import React from "react";
import { Link } from "react-router-dom";

const BlogsComponent = () => {
  return (
    <div>
      <div className="blog-footer-section-wrapper d-none d-sm-block">
        <div className="d-none d-sm-block">
          <div className="blog-footer-background-with-content">
            <img src={require("assets/images/footer_bg.png").default} alt="background 1" className="blog-footer-background-desktop" />
            <div className="blog-footer">
              <div className="d-flex flex-row align-items-center">
                <div className="col-12 left-wrapper">
                  <Link to="/catalysts" className="blog-footer-links">
                    Catalysts
                  </Link>
                  <br />
                  <Link to="/contact" className="blog-footer-links">
                    Help centre
                  </Link>
                  <br />
                  <Link to="/faq" className="blog-footer-links">
                    FAQ
                  </Link>
                  <br />
                  <Link to="/tnc" className="blog-footer-links">
                    Terms and conditions
                  </Link>
                  <br />
                  <Link to="/privacy_policy" className="blog-footer-links">
                    Privacy policy
                  </Link>
                  <br />
                  <Link to="/">
                    <span className="navbar-brand-logo">
                      <img src={require("assets/svgs/logo_white.svg").default} alt="logo-white" className="mt-4"/>
                    </span>
                  </Link>
                  <div className="company-name">
                    Perla Infotech Pvt Ltd®
                    <div>Copyrights 2021 &middot; All rights reserved</div>
                  </div>
                </div>
                <div className="col-12 right-wrapper">
                  <p className="follow-us">
                    Follow Lets Gtok on
                  </p>
                  <a href="https://www.facebook.com/letsgtok" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <img className="" src={require("assets/svgs/logo_facebook_white.svg").default} alt="1" />
                  </a>
                  <a href="https://www.instagram.com/letsgtok" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <img className="" src={require("assets/svgs/logo_instagram_white.svg").default} alt="1" />
                  </a>
                  <a href="https://www.linkedin.com/company/letsgtok/" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <img className="" src={require("assets/svgs/logo_linkedin_white.svg").default} alt="1" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  )
}

export default BlogsComponent;
