const blog = {
  name: 'yes-i-am-an-introvert',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/yes-i-am-an-introvert_LetsGtok.jpg',
  title: 'Yes, I am an introvert',
  date: '19 Dec 2021',
  meta_description: 'This story is about me and my feelings. If you do not like the sound of it, pretend you didn’t even read it.',
  meta_keywords: 'introvert,attitude,childhood,feelings,real experience,experience',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/letsgtok' target='_blank' style='color: #6930DB !important;'>Anonymous</a></div>`,
  body: `<div>
  <p>
  This story is about me and my feelings. If you do not like the sound of it, pretend you didn’t even read it.
  </p>
  <p class="text-center">
  <em>This is not an attitude - This is an altitude.</em>
  </p>
  <p>
  Being an introvert is not bad. But it's kind of important these days and frankly speaking hard too.
  </p>
  <p>
  Every introvert suppresses feelings, overthinks, lacks communication skills, fears public speaking & so much more.
  </p>
  <p>
  Every introvert faces people who judge, comment, and discourage them without thinking of an introvert ability.
  </p>
  <p>
  Now, I think you will understand why being an introvert is hard.
  </p>
  <p>
  But to be honest - an introvert faces more problems than others? Not physically, but emotionally.
  </p>
  <p>
  For the past two years, due to covid, it has become really hard to go out and enjoy nature, which I consider my only genuine friend.
  </p>
  <p>
  Moreover, I live alone. I do not smoke or drink.
  </p>
  <p>
  I don't have a girlfriend.
  </p>
  <p>
  Neither am I a celebrity.
  </p>
  <p>
  But,
  </p>
  <p>
  I do have emotions, just like any other person.
  </p>
  <p>
  I want to tell the world what I feel without getting judged and criticized.
  </p>
  <p>
  I want to tell the world - every feeling of mine without being commented on.
  </p>
  <p>
  I want to express my emotions without being suppressed because of anyone.
  </p>
  <p>
  So I thought I could share something with people where they can express what they feel without any criticism.
  </p>
  <p>
  And that is how I started my journey at LetsGtok.
  </p>
  <p class="text-center">
  <em>Share and Trade Feelings.</em>
  </p>
  <p>
  Sharing is attractive - just type or speak.
  </p>
  <p>
  I don't have to reveal my face to express my feelings. I started typing my feelings at LetsGtok, and soon enough, it became my best companion.
  </p>
  <p>
  Rather,
  </p>
  <p>
  This companion helped me to release my actual genuine emotions.
  </p>
  <p class="text-center">
  <em>No barriers - No comments - No judgments - No overthinking. <br />
  Simply sharing!</em>
  </p>
  <p>
  During my journey with LetsGtok so far, I understood; there was no problem with me.
  </p>
  <p>
  But, the genuine problems are my surroundings and the social platforms I'm using.
  </p>
  <p>
  This companion helped me to release my actual genuine emotions.
  </p>
  <p>
  These mediums are nothing but the cause of my laziness, anxiety, stress, FOMO, etc.
  </p>
  <p>
  Also, I understood that- feelings are an asset.
  </p>
  <p>
  You ask me why?
  </p>
  <p>
  Because nothing can be more valuable than the hidden emotions, one carries throughout the day.
  </p>
  <p>
  I mean, I have been holding on to so many emotions within me that might mean nothing to anyone. Still, they are everything to me because I have been feeling things that not a single valuable thing (materialistic) can replace.
  </p>
  <p>
  With feelings, it's not just the sad or negative emotions but those positive, happy ones as well.
  </p>
  <p>
  Every feeling is important in this world.
  </p>
  <p>
  By the way, do you know that 40% of the world's population are introverts? Extroverts can't survive either in a business or the outside world without introverts.
  </p>
  <p>
  So, never underestimate an introvert.
  </p>
  <p>
  If possible, try to support them to come out of their fears.
  </p>
  <p>
  And you never know our stories, our emotions may change someone's life at someplace.
  </p>
  <p>
  Let's together save introverts and their <strong><em>"Freedom of Feelings."</em></strong>
  </p>
  <p>
  One last thing, before I sign off -
  </p>
  <p class="text-center">
    <em>
    Introverts fear a lot. <br/>
    Introverts think a lot. <br/>
    Also, Introverts can achieve a lot.
    </em>
  </p>
</div>`
}

export default blog;
