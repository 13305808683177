import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ParentComponent = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="container-fluid">
			<div className="catalysts-expectations-wrapper d-none d-sm-block">
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close" />
				</Link>
				<div className="text">
					<h1 className="header">Expectations</h1>
					<div>
						<h5 className="header-key">A leader</h5>
						<p className="header-value">
							People with skills like integrity, and self-discipline empower others and if you have that potential come on board with us.
						</p>
					</div>
					<div>
						<h5 className="header-key">Revolutionary</h5>
						<p className="header-value">
							This attitude is a bonus and is required to modify the perception of those in the vicinity.
						</p>
					</div>
					<div>
						<h5 className="header-key">Excellent Communicator</h5>
						<p className="header-value">
							Catalysts don&apos;t have to be bright or extroverted to be effective. It is, nevertheless, critical that they are at ease and adept in a one-on-one conversation.
						</p>
					</div>
					<div>
						<h5 className="header-key">An optimistic</h5>
						<p className="header-value">
							Catalyst should be outgoing and have a welcoming, approachable public persona.
						</p>
					</div>
				</div>
				<img src={require("assets/images/catalysts/expectations_1.png").default} alt="expectations_1" className="header-img" />
			</div>

			<div className="catalysts-expectations-wrapper mobile d-sm-none justify-content-center">
				<h1 className="header-mobile pt-4 mt-3">Expectations</h1><br /><br /><br />
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close-mobile" width="5%" />
				</Link>
				<div className="text text-center">
					<div className="row align-items-center">
						<div className="col-12 mb-3">
							<h5 className="header-key">A leader</h5>
							<p className="header-value">
								People with skills like integrity, and self-discipline empower others and if you have that potential come on board with us.
							</p>
						</div>
						<div className="col-12 mb-3">
							<h5 className="header-key">Revolutionary</h5>
							<p className="header-value">
								This attitude is a bonus and is required to modify the perception of those in the vicinity.
							</p>
						</div>
						<div className="col-12 mb-3">
							<h5 className="header-key">Excellent Communicator</h5>
							<p className="header-value">
								Catalysts don&apos;t have to be bright or extroverted to be effective. It is, nevertheless, critical that they are at ease and adept in a one-on-one conversation.
							</p>
						</div>
						<div className="col-12 mb-3">
							<h5 className="header-key">An optimistic</h5>
							<p className="header-value">
								Catalyst should be outgoing and have a welcoming, approachable public persona.
							</p>
						</div>
					</div>
					<div className="col-12">
						<img src={require("assets/images/catalysts/expectations_mobile.png").default} alt="expectations_1" className="header-img" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParentComponent;
