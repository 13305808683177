const blog = {
  name: 'minimalistic-world',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/minimalistic-world_LetsGtok.jpg',
  title: 'Minimalistic world - Keep everything simple',
  meta_description: 'Do you wish to make a difference in the world? Then you must change yourself first...',
  meta_keywords: 'minimalistic,nomad,pain,emotions,life,difference,',
  date: '04 Sep 2021',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  body: `<div>
  <p style="text-align: center">
    <b>World of minimalism!!</b>  <br/>
  </p>
  <p style="text-align: center">
  "Do you wish to make a difference in the world? Then you must change yourself first."
  </p>
  <p>
    The truth is that the world can be changed. But before you can change the world, you must first alter yourself. It would be best if you adjusted your mindset, your habits, and your behavior. It is difficult to change oneself, yet it is essential to have a significant impact.
  </p>
  <p style="text-align: center">
    <b>So, what exactly is minimalism?</b> <br/>
  </p>
  <p style="text-align: center">
  "Simplicity, singleness, and clarity are the qualities that give our lives force, joy, and vividness, and the features that distinguish great art."
  </p>
  <ul>
    <li>
      <b>Minimalism means having fewer things:</b> <br/>
      <p>
        Minimalism entails consciously living with only the things we genuinely require—items that help achieve the goals. Let's take an example here like I want to get rid of the clutter in my life to focus on the essential things.
      </p>
      <p style="text-align: center">
      That's the "elevator pitch" response.
      </p>
      <p>
      What is your one-sentence elevator pitch? Ever had one, be honest? <br/>

      It may come as a surprise, but we aren't fans of oversimplification.
      </p>
      <p>
      Unfortunately, we're often obliged to present a simplified version of minimalism when speaking on dozens of five-minute media slots if we are not wrong here.
      </p>
    </li>
    <li>
      <b>It has to do with intentionality:</b> <br/>
      <p>
      Clarity, purpose, and intentionality characterize it. Being a minimalist boils down to consciously encouraging the things we value most and removing everything that gets in the way. It is a life that forces us to be intentional.
      </p>
    </li>
    <li>
      <b>It means freedom from the desire to own:</b> <br/>
      <p>
      The myth that the ideal life is found in acquiring things—possessing as much as possible—has pervaded modern civilization. This is, however, not entirely true. Minimalism frees you from the all-consuming drive to own things.
      </p>
    </li>
    <li>
      <b>It is internal, not external:</b> <br/>
      <p>
      It does not assist individuals in discovering freedom and unity in their hearts and souls after they have found liberation from exterior clutter.
      </p>
      <p>
      We believe that adopting this lifestyle is always a personal decision. It provides the space to address the deeper heart issues that affect our relationships and lives once the exterior clutter has been eliminated.
      </p>
    </li>
  </ul><br/>
  <p style="text-align: center">
    <b>
    Today's world is full of negativity and chaos. It can't harm to be less materialistic and more focused on the simple things in life, no matter how you look at it.
    </b>
  </p><br/>
  <ul>
    <li>
      <b>What exactly does it mean to lead a simple life?</b> <br/>
      <p>
      It involves living only with the things you genuinely require. It consists in getting rid of anything that keeps you from living with intention and freedom.
      </p>
    </li>
    <li>
      <b>Is living a simple lifestyle monotonous?</b> <br/>
      <p>
      Living a minimalist lifestyle is the polar opposite of monotony. It eliminates time-consuming activities that prevent us from spending quality time with our loved ones. We may choose what will characterize our life once we've gotten rid of the unneeded.
      </p>
    </li>
  </ul> <br/>
  <p style="text-align: center">
    <b>
    There's Never Been a Better Time to Live a Minimalist Lifestyle. The most exciting aspect of adopting minimalism is that it is on the verge of becoming famous.
    </b>
  </p> <br/>
  <p>
  We would love to hear your feelings on this idea of living with minimalism or a minimalistic lifestyle. Share them with us on <a href="https://app.letsgtok.com/create_post" target="_blank" style="color: #6930db !important;"><b>LetsGtok</b></a>
  </p>
</div>`
}

export default blog;
