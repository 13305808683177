import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";

// App components
import Routes from "routes/Routes";
import { CookieNotification } from "components";

// AOS
import "aos/dist/aos.css";
import AOS from "aos";

// Toast for popups
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
			<Helmet>
				<title>Lets Gtok &middot; Feelings are assets</title>
		    <meta name="theme-color" content="#000000" data-react-helmet="true" />
		    <meta name="description" content="A digital asset platform to share and trade feelings. Share feelings confidently. Trade feelings securely." data-react-helmet="true" />
		    <meta name="keywords" content= "lets gtok,gtok,digital assets,data currency,share feelings,trade feelings,trade,share,non fungible tokens,nft,lets talk" data-react-helmet="true" />
			</Helmet>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
      />
      <CookieNotification />
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
