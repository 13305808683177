const blog = {
  name: 'share-feelings',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/share-feelings_LetsGtok.png',
  title: 'Lets\' share our FEELINGS!',
  meta_description: 'Sharing or expressing our sentiments is important, isn’t it?...',
  meta_keywords: 'feelings,emotions,share,friends,benefits',
  date: '22 Aug 2021',
  body: `<div>
  Sharing or expressing our sentiments is important, isn’t it? <br/>
  Well, we believe so, because it is critical to impart our emotions to others as they help us in overcoming the difficulties that we have been holding on to for a while. <br/><br/>
  We all know how much people are busy establishing their life that they don't have time for themselves (mental fitness) or their emotions in these challenging times, like how they're feeling or how things are affecting their mental health. <br/><br/>
  People nowadays have forgotten what sentiments are, what they mean, and what sharing them will bring out in them since the times have made them far too realistic.<br/><br/>
  The importance of friends and family cannot be overstated. Though, we cannot always share everything with them. Practically speaking, this is impossible because certain things are best kept hidden within us.<br/><br/>
  But do you know that keeping things packaged can be unfortunate and could prompt sadness on the off chance if you don't converse with somebody? I mean we all need someone to rely on, not physically but certainly mentally.<br/><br/>
  I understand that not every person gets what you are going through, but rather opening up to others just to let your sentiments out and vent about an issue that has been building is an extraordinary method to release all that pressure that has been working for such a long time.<br/><br/>
  Just give it a shot; I guarantee that expressing your feelings will lift your spirits, improve your day, and provide clarity to your vision. On the off chance that you feel awkward conversing with a dear companion or relative, you can generally come here at <a href='https://app.letsgtok.com' target="_blank" style="color: #6930db !important;">Lets Gtok</a> to vent out your feelings.<br/><br/>
  We allow you to express yourself while keeping your identity safe, as you can share your feelings anonymously. All of your emotions are public but the authority of posting as an anonymous or as a real person is in your hands. We place a high value on privacy because we believe feelings are valuable assets, and the assets you share on our platform are precious to us as well.<br/><br/>
  We will always provide you the help and support when you need it, or you can visit <a href='https://letsgtok.com' style="color: #6930db !important;">www.letsgtok.com</a> and can join our <a href='https://discord.gg/7H7pBb8gzE' target="_blank" style="color: #6930db !important;">Discord community</a>. Simply sign up for free and you are good to go.<br/><br/>
  At <b>Lets Gtok</b>, you can express yourself without fear of being condemned. We understand and care for your sentiments in a way that few others can. Our mission is to offer you a space where you can express whatever you want without worrying about what others will think.<br/><br/>
  We believe in giving your emotions the appropriate space to call home. As we don’t have any comment section so you can keep your home to yourself, no one can pass judgment on your assets (feelings). So there’s no scope for negativity.<br/><br/>
  We'd rather allow you to see your emotions as assets in your life. You will have greater accomplishment and joy if you can connect with your feelings and sentiments healthily.<br/><br/>

  Do you realize how expressing your emotions can help you?<br/>
  So, let me simply explain a few of the advantages that can not only boost your mental health but also make your mood rise.
  <br/><br/>
  <ul>
    <li>Aids in the development of better communication.</li>
    <li>Gives you a sense of belonging.</li>
    <li>Increase acceptance.</li>
    <li>Helps rethink issues.</li>
  </ul>
  We believe hiding is never a viable device and these negative thoughts can get you unconscious. Yet, driving yourself to neglect contemplating negative thoughts can blow up. So, if you at any point feel overpowered, spill your sentiments out by trusting us.<br/><br/>
  We value you and your sentiments, whether positive or negative. Share and release the stress Gtokers’
  <br/><br/>
  <h4 class="text-center">LetsGtok!</h4>
</div>`
}

export default blog
