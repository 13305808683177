import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ParentComponent = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="container-fluid">
			<div className="catalysts-activities-wrapper d-none d-sm-block">
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close" />
				</Link>
				<div className="text">
					<div className="row align-items-center">
						<div className="col-7">
							<div>
								<h5 className="header-key">Perform multiple tasks</h5>
								<p className="header-value">
									Come up with marketing concepts and cooperate with the admin team to grow Lets Gtok’s user base.
								</p>
							</div>
							<div>
								<h5 className="header-key">Boost & Enhance Creativity</h5>
								<p className="header-value">
									Your creativity will see no bounds as a Catalyst. Learn and grow with your peers and Lets Gtok.
								</p>
							</div>
							<div>
								<h5 className="header-key">Be a Leader</h5>
								<p className="header-value">
									Take initiative and work towards making Lets Gtok a part of everyone’s daily lives.
								</p>
							</div>
							<div>
								<h5 className="header-key">Spread positivity</h5>
								<p className="header-value">
									Take this program as fun not a burden, that way you will be able to perform well for everyone (you, us, users).
								</p>
							</div>
							<div>
								<h5 className="header-key">Should be socially active</h5>
								<p className="header-value">
									Catalyst will help in an assortment of endeavors, offering Q&As, and keeping in contact with multiple people.
								</p>
							</div>
						</div>
						<div className="col-5 text-center">
							<img src={require("assets/images/catalysts/activities_1.png").default} alt="expectations_1" className="header-img" />
							<h1 className="header">Activities</h1>
						</div>
					</div>
				</div>
			</div>

			<div className="catalysts-activities-wrapper mobile d-sm-none justify-content-center">
				<h1 className="header-mobile pt-4">Activities</h1><br /><br /><br />
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close-mobile"  width="5%"/>
				</Link>
				<div className="text text-center">
					<div className="row align-items-center">
	
							<div className="col-12 mb-5">
								<h5 className="header-key">Perform multiple tasks</h5>
								<p className="header-value">
									Come up with marketing concepts and cooperate with the admin team to grow Lets Gtok’s user base.
								</p>
							</div>
							<div className="col-12 mb-5">
								<h5 className="header-key">Boost & Enhance Creativity</h5>
								<p className="header-value">
									Your creativity will see no bounds as a Catalyst. Learn and grow with your peers and Lets Gtok.
								</p>
							</div>
							<div className="col-12 mb-5">
								<h5 className="header-key">Be a Leader</h5>
								<p className="header-value">
									Take initiative and work towards making Lets Gtok a part of everyone’s daily lives.
								</p>
							</div>
							<div className="col-12 mb-5">
								<h5 className="header-key">Spread positivity</h5>
								<p className="header-value">
									Take this program as fun not a burden, that way you will be able to perform well for everyone (you, us, users).
								</p>
							</div>
							<div className="col-12 mb-5">
								<h5 className="header-key">Should be socially active</h5>
								<p className="header-value">
									Catalyst will help in an assortment of endeavors, offering Q&As, and keeping in contact with multiple people.
								</p>
							</div>
						</div>
						<div className="col-12 text-left">
							<img src={require("assets/images/catalysts/activities_1.png").default} alt="expectations_1" className="header-img" />

						</div>
				</div>
			</div>
		</div>
	);
};

export default ParentComponent;
