const blogs = [
  {
    name: 'new-year-new-me',
    template: require('./new-year-new-me').default
  },
  {
    name: 'christmas-now-and-then',
    template: require('./christmas-now-and-then').default
  },
  {
    name: 'yes-i-am-an-introvert',
    template: require('./yes-i-am-an-introvert').default
  },
  {
    name: 'reminiscing-on-our-lives',
    template: require('./reminiscing-on-our-lives').default
  },
  {
    name: 'revamp-version-3-0',
    template: require('./revamp-version-3-0').default
  },
  {
    name: 'minimalistic-world',
    template: require('./minimalistic-world').default
  },
  {
    name: 'zena',
    template: require('./zena').default
  },
  {
    name: 'is-being-an-introvert',
    template: require('./is-being-an-introvert').default
  },
  {
    name: 'what-can-we-do',
    template: require('./what-can-we-do').default
  },
  {
    name: 'fomo-in-this-century',
    template: require('./fomo-in-this-century').default
  },
  {
    name: 'feelings-must-be-expressed',
    template: require('./feelings-must-be-expressed').default
  },
  {
    name: 'e-for-empathy',
    template: require('./e-for-empathy').default
  },
  {
    name: 'what-happens-when',
    template: require('./what-happens-when').default
  },
  {
    name: 'is-personal-data',
    template: require('./is-personal-data').default
  },
  {
    name: 'trade-feelings',
    template: require('./trade-feelings').default
  },
  {
    pin: true,
    name: 'share-feelings',
    template: require('./share-feelings').default
  }
]

export default blogs;

// {
//   name: 'how-trading-of-feelings',
//   template: require('./how-trading-of-feelings').default
// },
