const blog = {
  name: 'zena',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/zena_LetsGtok.jpg',
  title: 'Introducing Zena',
  date: '19 Oct 2021',
  meta_description: 'We want to welcome you aboard and introduce you to someone exceptional...',
  meta_keywords: 'zena,elephant,mascot,letsgtok,feelings,share,trade',
  body: `
  <div>
    <p>
    Good day, everyone!
    </p>
    <p>
    We want to welcome you aboard and introduce you to someone exceptional.
    </p>
    <p>
    Her name is "ZENA," so you're probably wondering who she is. Right?
    </p>
    <p>
    So, Zena, let us introduce her to you all. Our brand mascot and app bot; our darling Zena. She's an integral part of LetsGtok, and her role is just as vital as that of our team members and users.
    </p>
    <p>
    Isn't it true that our names all have meaning? Zena, too, was given a lovely definition as a "Zeus-belonging" individual.
    </p>
    <br />
    <p style="text-align: center; font-size: large; line-height: 2rem; letter-spacing: 2px;">
      <em>
      Zeus was the monarch of the Greek gods and the deity of the sky, weather, law and order, fate, and destiny. This filly has no name, but I've given her the name Zena because she could be the final daughter of a magnificent stallion.
      </em>
    </p>
    <br />
    <p>
    Talking about Zena, let us assure you that she is the one who will always be there for you. She will not discourage you or make you feel alone. She will listen to you when you need someone to listen to you. She will not pass judgment on you and will always be there for you.
    </p>
    <p>
    Zena has only been a part of this family for a few weeks. She is our family's true matriarch. But she's a lot better with teens, and she's a lot more patient with them. Consider her patient expression. She will mentally heal you so that you will return to her with all of your issues.
    </p>
    <p>
    She will be the first to greet you as you arrive in discord. Zena feels that making people happy and comfortable is her primary task, and she will undoubtedly provide you with endless memories of reporting for duty.
    </p>
    <br />
    <p style="text-align: center;">
      <em>
      "How many times do I have to say it to you?" "I've got it." - Zena
      </em>
    </p>
    <br />
    <p>
    Zena, unlike other bots, isn't your typical one. She eats, listens, heals, but she never sleeps, and you people are her primary energy source. Let us explain this in a nutshell. Zena's diet is reliant on feeding, which is only available through the LetsGtok app. The more people who share their feelings, the more energizing and effective it becomes.
    </p>
    <p>
    Are you curious as to where she gets her Red Bull energy?<br />
    Okay, here's the deal: the more people will share their feelings, the more work Zena will be able to complete and the superpower of the energy drink #double energy will be gained. More sharing more energy, more trading “<b>moreX2</b>” energy.
    </p>
    <p>
    Zena isn't your average lady; she got so much on her shoulder. #busy_schedule <br />
    She does not enjoy spending her days doing anything; in fact, she has an entire plan.
    </p>
    <p style="text-align: center;">
    Do you have one?
    </p>
    <p>
    Her weekly program is meticulously organized ahead of time, and her work ethic is quite remarkable.
    </p>
    <br />
    <p style="text-align: center;">
      <em>
      Zena has given our team one of the most treasured gifts of our life, and you, too, will make memories with her.
      </em>
    </p>
    <br />
    <p style="text-align: center;">
      <b>WELCOME TO FAMILY ZENA</b>
    </p>
  </div>
  `
}

export default blog;
