export const QUERIES = [
  {
    key: 'General',
    values: [
    {
      question: "Is LetsGtok a novel concept?",
      answer: "Yes. Humans will be able to live and thrive in the future, thanks to digital assets. We are happy to be a part of this revolutionary development, which is transforming digital data into digital assets!"
    },
    {
      question: "How does Lets Gtok work?",
      answer: 'Share feelings and Trade feelings are two key features of LetsGtok. <ul> <li>Sharing feelings is a feature that allows everyone to freely and openly express their emotions without fear of being judged or condemned.</li> <li> Trade feelings is a feature that allows you to earn and receive rewards from your emotions and feelings in the real world. </li> </ul>'
    },
    {
      question: "What makes sharing feelings so remarkable?",
      answer: "LetsGtok is unlike any other online platform, it allows users to share their emotions. We understand that feelings and emotions are valuable, and that we must accept and appreciate each other's emotions without condemning or judging them because we never know what a person has been through or is going through. As a result, we should never treat them with disrespect or make negative remarks about them. LetsGtok designed this feature with the utmost care, compromising negativity in no way."
    },
    {
      question: "What makes trade feelings unique?",
      answer:"LetsGtok's vision of trading feelings is unlike any other online platform. We all know how valuable sentiments and emotions are, therefore giving them some sort of  value necessitates complete ownership. That is why we share ownership with our users, allowing them to set the trade price at any time. Learn more about the Lets Gtok trading feature in The Trade section."
    },
    {
      question: "Can I share my feelings anonymously?",
      answer:"Yes, we offer a function that allows anyone to communicate their feelings anonymously. Because we believe that exposing our identity can lead to judgments and negativity, so we have made an anonymous option available to our users."
    },
    {
      question: "How do I start making money using Lets Gtok?",
      answer:"Lets Gtok is a place where you can not only express your feelings, but also profit from them. Trading is a fantastic way for you to make money in return for your emotions."
    },
    {
      question: "How is Lets Gtok valuable to me?",
      answer:"Allow Lets Gtok to be there for you at all times, where you may safely express anything you're feeling and anytime you want. As a result, you will feel lighter."
    },
    {
      question: "Is it possible for me to join LetsGtok as a partner?",
      answer: 'Yes, absolutely. Lets Gtok is open to a variety of collaborations. Associating with individuals as influencers and ambassadors, collaborating with SME\'s in near future. Send us an email at <a href="mailto:letsgtok@gmail.com" class="text-violet">letsgtok@gmail.com</a> if you\'d like to collaborate, associate, or assist, or if you\'d need additional information. Within two working days, a member of our team will contact you.'
    },
    {
      question: "What does the future hold for Lets Gtok?",
      answer: "We all understand how essential sentiments and emotions are in our fast-paced lives. As a result, we strive to deliver actual value to people, as we feel emotions are valuable assets. We're dedicated to making our friends', family's, and users' lives easier, better, and less stressful in both emotional and financial ways. Ultimately, we want to turn the digital/social world into a digital asset-based one."
    },
    {
      question:"Why is it good to discuss one's emotions?",
      answer:"By expressing your feelings, you can better connect with and understand others, as well as yourself. Talking about your feelings might sometimes relieve whatever stress you're experiencing. Sharing can sometimes relieve stress and improve one's mood and mental health."
    },
    {
      question: "Is Lets Gtok a neuroscience and psychology-based brand?",
      answer:"We help users in understanding their emotional patterns in order to live a stress-free life. Lets Gtok is a completely social network that assists everyone, regardless of their profession. People can come here and express or trade their feelings without feeling or facing any negativity. The website encourages our users to be positive by allowing them to come here and express their joy, sadness, anger, or any other emotion."
    }
  ]
  },
  {
    key: 'Features',
    values: [
    {
      question: "I don’t want to reveal my identity while posting my feelings. What should I do?",
      answer: "You can simply click the eye button to stay anonymous while sharing your feelings so that no one can see your identity."
    },
    {
      question: "Can I measure my feelings?",
      answer: "Yes, you can measure your feelings with our integrated analytics option on the profile page."
    },
    {
      question: "Can I track my feelings?",
      answer: "Yes, you can track your feelings through our analytics feature provided on your profile page easily."
    },
    {
      question: "How are feelings analytics helpful?",
      answer: "Analytics are really useful in measuring your feelings in a timely manner. Based on your behaviour patterns, it might assist you in improving your decision-making abilities."
    },
    {
      question: "Can I predict my future feelings? ",
      answer: "Yes, you can predict future feelings based on current and past feelings. We are integrating our platform with Artificial Intelligence to predict your future feelings. This update will be released in Let’s Gtok v5.0"
    },
    {
      question: "What happens when you report a post?",
      answer: "Your request will be sent to our team where it will be looked upon for further processing."
    }
  ]
  },
  {
    key: 'Trade',
    values: [
    {
      question: "How to trade (sell) my own posts?",
      answer: "Create, Choose, and Post. Create a feeling, choose a trading price, and simply post it."
    },
    {
      question: "How can I Trade my friend’s locked assets?",
      answer: "Simply click on the asset you want to Trade. You’ll see the invoice details then click Next, enter your wallet security code, and finally hit the pay button."
    },
    {
      question: "Is there any refund after buying locked assets?",
      answer: "No, there is no amount refunded once the transaction is processed and successful."
    },
    {
      question: "How can I recharge my wallet?",
      answer: "Follow these steps:<ol><li>Go to your wallet page and then click on Recharge.</li><li>Enter recharge amount and then fill up your bank details.</li><li>Click on Proceed and the process will be initiated.</li>"
    },
    {
      question: "How can I withdraw the money from my wallet?",
      answer: 'Follow these steps: <ol> <li>Go to your wallet page and then click on Withdraw.</li><li>Enter your withdrawal amount (a minimum balance of $75 is required) and then click next.</li><li>Enter your bank details and then click on the Withdraw button (Your request will be processed by the 15th of that month/next month).</li></ol>'
    },
    {
      question: "Is there any minimum amount limit for withdrawal requests?",
      answer: "Yes, there is a minimum amount of $75 (This changes from country to country). You cannot request a withdrawal if it is less than the specified amount."
    },
    {
      question: "Do I need to request withdrawal every month?",
      answer: "Yes, it is required to enter withdrawal details every month in the current version Lets Gtok v3.0 But we will add an auto-withdrawal option from Lets Gtok v4.0."
    },
    {
      question: "Can I transfer my wallet’s money to someone else’s wallet?",
      answer: "No, you can't transfer your wallet's money to someone else's wallet."
    },
    {
      question: "How long would it take for me to get money after I request a withdrawal?",
      answer: "Every month on the 14th or 15th, all withdrawal requests will be processed. Once your request is approved, your funds will be deposited to your domestic bank account within three working days. It may take up to 10 working days if it is an overseas bank account."
    },
    {
      question: "Is there a price range for trading assets?",
      answer:"Yes. You can set your asset's price within the Let's Gtok price range. The lowest price is $1 and the highest price is $10000. If the transaction value exceeds $1000, we will request KYC documentation as well as a few more documents that are required to prevent fraud and intangible transactions. This pricing range varies from one country to another."
    },
    {
      question:"Is it possible to change the price once it has been shared?",
      answer:"No, once you've chosen your post price for the first time, you won't be able to change it."
    },
    {
      question:"Are there any requirements to trade?",
      answer:"No, there are no restrictions for our users to start trading."
    },
    {
      question:"Is there any service fee while purchasing assets?",
      answer:"No. There is no service fee charged while purchasing assets."
    },
    {
      question:"Is there any service fee while withdrawing money from a wallet?",
      answer:"Yes. There is a 20% fee charged on every withdrawal request."
    },
    {
      question:"Can I freeze my wallet? ",
      answer:"Yes, you can freeze your wallet at any time. If your wallet is frozen, you’re no longer recharge or withdraw or trade-in Lets Gtok."
    },
    {
      question:"Can I unfreeze my wallet? ",
      answer:"Yes, you can unfreeze your wallet at any time and do all your transactions in a normal manner."
    },
    {
      question:"Why can’t I see my recharge transactions on the wallet page?",
      answer:'Sometimes, your recharge transactions may take longer than expected either due to heavy server requests or any other security issues. At this time, you can contact us at <a className="text-violet" href="mailto:letsgtok@gmail.com">letsgtok@gmail.com</a> Your request will be resolved within 24 hours.'
    },
    {
      question:"Why can’t I see my purchasing transactions on the wallet page?",
      answer:'Sometimes, your purchasing transactions may take longer than expected due to heavy server requests or other security issues. At this time, you can contact us at <a className="text-violet" href="mailto:letsgtok@gmail.com">letsgtok@gmail.com</a> Your request will be resolved within 24 hours.'
    },
    {
      question: "Is it mandatory to trade in LetsGtok?",
      answer:"Absolutely not. Trading is completely optional."
    }
  ]
  }
]
