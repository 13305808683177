import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "assets/svgs/logo.svg";

const ParentComponent = () => {
	useEffect(() => {
		// window.scrollTo(0, 0);
	}, []);

  return (
		<div className="catalysts-wrapper">
			{/* Start: Main section for desktop devices */}
			<div className="main-section d-none d-sm-block">
				<img src={require("assets/images/catalysts/catalysts.png").default} alt="logo-white" className="catalyst_main_banner"/>
				<Link to="/" className="navbar-brand" >
					<div className="navbar-brand-img">
						<img src={logo} alt="@letsgtok"/>
					</div>
				</Link>
				<div className="bottom-text">
					<div className="catalysts-text">
						So, you want to make a positive effect while also honing your leadership and connecting skills? Join our discord server to get started and give wings to your creative side by becoming a Lets Gtok CATALYST.
 						<br/><br/>
						<a href="https://discord.gg/7H7pBb8gzE" target="_blank" rel="noopener noreferrer" className="catalysts-btn">
							Join Now
						</a>
					</div>
				</div>
			</div>
			{/* End: Main section for desktop devices */}
			{/* Start: Main section for mobile devices */}
			<div className="main-section-mobile d-sm-none">
				<img src={require("assets/images/catalysts/catalysts_bg_mobile.png").default} alt="logo-white" className="catalyst_intro_banner"/>
				<Link to="/" className="navbar-brand-mobile" >
					<div className="navbar-brand-img-mobile align-self-center">
						<img src={logo} alt="@letsgtok" className="navbar_brand_catalyst align-self-center"/>
					</div>
				</Link>

				<div className="bottom-text-mobile">
					<div className="catalysts-text-mobile col-11 mx-3">
						So, you want to make a positive effect while also honing your leadership and connecting skills? Join our discord server to get started and give wings to your creative side by becoming a Lets Gtok CATALYST.
						<br/><br/>
						<a href="https://discord.gg/7H7pBb8gzE" target="_blank" rel="noopener noreferrer" className="catalysts-btn mt-3">
							Join Now
						</a>
					</div>
				</div>
			</div>
			{/* End: Main section for mobile devices */}
			{/* Start: Components section */}
			<div className="container-fluid">
				<div className="row mr-0">
					<div className="col-12 col-sm-4 catalyst-section">
						<Link to="catalysts_benefits">
							<img src={require("assets/images/catalysts/benefits.png").default} alt="benefits" />
						</Link>
					</div>
					<div className="col-12 col-sm-4 catalyst-section">
						<Link to="catalysts_duties">
							<img src={require("assets/images/catalysts/activities_0.png").default} alt="duties" />
						</Link>
					</div>
					<div className="col-12 col-sm-4 catalyst-section mb-4">
						<Link to="catalysts_expectations">
							<img src={require("assets/images/catalysts/expectations.png").default} alt="expectations" />
						</Link>
					</div>
				</div>
			</div>
			{/* End: Components section */}
		</div>
  );
};

export default ParentComponent;
