import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blogs from "constants/blogs";

const BlogsComponent = () => {
	const [blogs, setBlogs] = useState('');
	const [pinnedBlog, setPinnedBlog] = useState('');

	useEffect(() => {
		window.scrollTo(0, 0);

		async function loadBlogs() {
			let local = []
			Blogs.map(b => {
				if (b.pin) {
					setPinnedBlog(b.template)
				} else {
					local.push(b.template);
					setBlogs([...local]);
				}
				return b;
			});
		}

		if (!blogs) {
			loadBlogs();
		}
	}, [blogs]);

	return pinnedBlog && blogs && blogs[0] && (
		<div>
			<div className="container blogs-wrapper px-3 px-sm-0">
				{/* pinned blog */}
				<Link to={`/blogs/${pinnedBlog.name}`} className="row align-items-center">
					<div className="col-12 col-sm-7 mt-5">
						<img alt={pinnedBlog.meta_keywords || pinnedBlog.title} className="blog-img pinned" src={pinnedBlog.banner_url} />
					</div>
					<div className="col-12 col-sm-4 mx-1 d-none d-sm-block">
						<span className="btn btn-violet-outline">
							{pinnedBlog.category}
						</span>
						<h3 className="mt-4 blog-header" dangerouslySetInnerHTML={{ __html: pinnedBlog.title }}></h3>
						<div>
							{pinnedBlog.date}
						</div>
					</div>

					<div className="d-sm-none">
						<div className="d-flex flex-column align-items-center">
							<span className="btn btn-violet-outline mt-3">
								{pinnedBlog.category}
							</span>
							<h3 className="mt-4 blog-header" dangerouslySetInnerHTML={{ __html: pinnedBlog.title }}></h3>
							<div>
								{pinnedBlog.date}
							</div>
						</div>
					</div>
				</Link>
				{/* col-6 blogs */}
				<div className="row justify-content-evenly">
					<div className="col-12 col-sm-6 mt-5">
						<Link to={`/blogs/${blogs[0].name}`}>
							<div className="card mt-5">
								<img alt={blogs[0].meta_keywords} className="card-img-top blog-img" src={blogs[0].banner_url} />
								<span className="btn btn-violet-outline badge-category">
									{blogs[0].category}
								</span>
								<div className="card-body">
									<Link to={`/blogs/${blogs[0].name}`} className="blog-header">
										{blogs[0].title}
									</Link>
									<div className="small">
										{blogs[0].date}
									</div>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-12 col-sm-6 mt-5">
						<Link to={`/blogs/${blogs[1].name}`}>
							<div className="card mt-5">
								<img alt={blogs[1].meta_keywords} className="card-img-top blog-img" src={blogs[1].banner_url} />
								<span className="btn btn-violet-outline badge-category">
									{blogs[1].category}
								</span>
								<div className="card-body">
									<Link to={`/blogs/${blogs[1].name}`} className="blog-header">
										{blogs[1].title}
									</Link>
									<div className="small">
										{blogs[1].date}
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
				{/* col-3 blogs */}
				<div className="row">
					{
						blogs.map((b, idx) => {
							if (idx < 2) {
								return <div key={idx}></div>;
							}
							return (
								<div className="col-12 col-sm-4 mt-5" key={idx}>
									<Link to={`/blogs/${b.name}`}>
										<div className="card mt-5">
											<img alt={b.meta_keywords} className="card-img-top blog-img" src={b.banner_url} />
											<span className="btn btn-violet-outline badge-category">
												{b.category}
											</span>
											<div className="card-body">
												<Link to={`/blogs/${b.name}`} className="blog-header">
													{b.title}
												</Link>
												<div className="small">
													{b.date}
												</div>
											</div>
										</div>
									</Link>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>

	);
};

export default BlogsComponent;
