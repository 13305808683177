const blog = {
  name: 'trade-feelings',
  category: 'Trading',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/trade-feelings_LetsGtok.png',
  title: 'Trade our feelings',
  date: '22 Aug 2021',
  meta_description: 'Wondering how someone could trade their feelings...',
  meta_keywords: 'feelings,emotions,share,trade,sharing,trading,wallet,sell,buy',
  body: `<div>
  I'm sure you were taken aback by the title, wondering how someone could trade their feelings. Well, let me simply say that everything in the world has a worth and so do our feelings.
<br/><br/>
Talking about our emotions; do they matter to anyone? What are your thoughts? Share them with us <a href='https://app.letsgtok.com' target="_blank" style="color: #6930db !important;">here.</a>
<br/><br/>
Taking ourselves as an example, everything we own, including our feelings, is valuable.
<br/><br/>
Our emotions/feelings are our assets, and we are the owner, so we can say that we now have the copyright. See how nice reading the word copyright makes one feel? Have you ever had or felt this sense of belonging and ownership to anything?
<br/><br/>
Not only does the physical asset have monetary worth, but everything has some sort of value.
<br/><br/>
Here at <strong>LetsGtok</strong>, you can transform your emotions into digital assets. Isn’t it cool? Despite the fact that they are the most valuable thing in one's life, you can now trade your feelings.
<br/><br/>
Have you heard of NFTs?  <br/>
Just like we trade digital coins/tokens on the NFT marketplace, here at LetsGtok you can now trade your feelings in the form of text or audio. This is, believe it or not, the easiest way to make some additional cash. People will be paying now only to get a glimpse of your emotion since you are now the boss of your sentiments.
<br/><br/>
It's wonderful to feel valued and special. Now you don't have to worry about whether or not someone will listen to you because we are always here for you. LetsGtok will never let you down or make you feel insignificant or that your feelings are unimportant. We'll always like you, always support your feelings, and always assist you in adding value to them.
<br/><br/>
Well, there's an adage that says you can't control your money if you can't control your emotions. But have you considered how much value you could get by loosening up your emotions and venting them here?
<br/><br/>
I mean, isn't it a fantastic concept!
<br/><br/>
Did it grab your interest? If so,  give it a shot, and don't forget to tell your friends and family about it. We are confident that the idea we have presented here will astound them as well.
  <br/><br/>
  <div class="text-center mt-4">
  <a href="https://app.letsgtok.com/app/create_post" target="_blank"><button class="btn btn-violet-v2 text-white">Start trading</button></a>
  </div>
</div>`
}

export default blog;
