const blog = {
  name: 'feelings-must-be-expressed',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/feelings-must-be-expressed_LetsGtok.jpg',
  title: 'Feelings must be expressed, but have you ever questioned why?',
  date: '21 Sep 2021',
  meta_description: 'Ever wondered why a particular scent makes our hearts skip a beat...',
  meta_keywords: 'feelings,emotions,share,positive,sentiments,trigger',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/letsgtok/' target='_blank' style='color: #6930DB !important;'>Zena</a></div>`,
  body: `
  <div>
  Ever wondered why a particular scent makes our hearts skip a beat or an old tune makes us cry? <br/><br/>

  Everything we go through is processed by our brains, which assigns an emotion to it. Starting from the breakfast we eat in the morning to the TV show we watch at night, everything is connected. It is one of the most important activities of the brain. <br/><br/>

  To occur and to experience good emotions, we must communicate our sentiments appropriately. <br/><br/>

  When we don't discuss our sentiments, we end up with too many emotions and become irritated for the smallest of reasons, thereby hurting our loved ones. Too many emotions occupying the heart, mind, and soul can harm a person's mental health, so it's preferable to let them out. What's going to happen? Why are people so fearful of revealing or expressing their emotions? Don't be frightened, it's just life; enjoy it, chill out, and make sure your head, heart, and soul are at ease. <br/><br/>

  While you may understand the importance of healthy emotional expression on a logical level, figuring out how to do so isn't always easy. Fortunately, there are a variety of approaches to promoting healthy emotional expression; here are 12 suggestions: <br/><br/>

  <strong>1. Talk positively:</strong> <br/>

  We all have an internal argument going on, which can be ugly and harmful at times. Healthy self-expression will be difficult if you are having a negative internal dialogue. Check to see if the messages in your head are negative, and if they are, develop a strategy to replace them with more positive ones. <br/><br/>

  <strong>2. Pay attention to others:</strong> <br/>

  It's a good idea to keep in mind. It hurts to be ignored, so pay attention to your family, friends, and coworkers. You'll be in a far better position to respond with empathy and understanding. <br/><br/>

  <strong>3. Recognize your triggers:</strong> <br/>

  People, situations, and locations are frequently linked. If particular triggers cause you to feel emotionally inhibited, it may be worth avoiding them as much as possible. <br/><br/>

  <strong>4. Experiment with spirituality:</strong> <br/>

  Joy is prayer; joy is strength; joy is love; joy is a net of love through which you may catch souls.
  Many individuals have found spirituality to be a source of strength, and if you need a little more help expressing your emotions, it might be beneficial to you as well. <br/><br/>

  <strong>5. Teach young children emotional words:</strong> <br/>

  Children frequently lack the verbal skills necessary to convey their emotions. Children will be helped to understand the terms for different emotions by using resources such as faces conveying different emotions. <br/><br/>

  <strong>6. Demonstrate empathy:</strong> <br/>

  Empathy builds ties that allow us to be emotionally in sync with others, whether among family, friends, or coworkers. <br/><br/>

  <strong>7. Remove all sources of distraction:</strong> <br/>

  Distractions keep many people's attention prisoner, whether in the form of cell phones, television, music, or video games. You will be better able to delve into your inner feelings and express them outward once you have turned off the noise. <br/><br/>

  <strong>8. Be a role model for emotional expression:</strong> <br/>

  Children who see adults healthily expressing a range of emotions are more likely to do so themselves. Show young kids what healthy emotional expression looks like if you are someone who spends time with them. <br/><br/>

  <strong>9. Be forgiving:</strong> <br/>

  The indescribable thrill of forgiving and being forgiven creates a euphoria that may make the gods envious. Holding a grudge, whether against yourself or someone else is the opponent of expression. You will open your heart and intellect to good expression if you are free of resentment. <br/><br/>

  <strong>10. Get in the habit of accepting things:</strong> <br/>

  “Acceptance is the only way to find happiness.”<br/>
  Accept the areas of your life that you have no control over. This will improve your mood while also allowing your thoughts to be more emotionally expressive. <br/><br/>


  <strong>11. Be appreciative of what you have:</strong> <br/>

  Happiness is a form of thankfulness in and of itself. It's difficult to be dissatisfied and grateful at the same time. Appreciate what you have, and you'll be able to express happiness more easily. <br/><br/>
  <div class="text-center">
  <h5 class="mb-4">Give us a chance, we won’t disappoint you.</h5>
  <a href="https://app.letsgtok.com/app/create_post" target="_blank"><button class="btn btn-violet-v2 text-white">Lets Share</button></a>
  </div>
  </div>
  `
}

export default blog;
