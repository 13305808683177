import React from "react";
import PropTypes from 'prop-types'
import  HeaderComponent  from "components/common/header/component";
import  FooterComponent from "components/common/footer/component";
import  MobileFooterComponent  from "components/common/mobile_footer/component";

const DefaultLayout = (props) => {
  return (
    <div>
      <HeaderComponent/>
      <div className="my-5 pt-3">
        {props.children}
      </div>
      <FooterComponent/>
      <MobileFooterComponent />
    </div>
  )
};

DefaultLayout.propTypes = {
  children: PropTypes.any
}

export default DefaultLayout;
