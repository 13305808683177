import React from "react";
import { useLocation } from "react-router-dom";

import BlogsComponent from "./blogs/component";
import CommonComponent from "./common/component";

const FooterComponent = () => {
  const location = useLocation();

  return location.pathname.includes('/blogs')
    ? <BlogsComponent />
    : <CommonComponent />
};

export default FooterComponent;
