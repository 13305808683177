const blog = {
  name: 'is-being-an-introvert',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/is-being-an-introvert_LetsGtok.jpg',
  title: 'Is being an introvert like living in paradise?',
  date: '16 Oct 2021',
  meta_description: 'Every single one of us is unique. Some people are drawn to...',
  meta_keywords: 'feelings,emotions,share,positive,speak,hard,introvert',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  body: `<div>
  <p>
  Every single one of us is unique. Some people are drawn to large gatherings and chatter, finding serenity amid the chaos. Others see the beginnings of their next artwork amid a scattering of leaves and twisted twigs.
  </p>
  <p>
  Then there are those among us who will never feel at rest unless we are in the presence of silence. With that in mind, having something consistent and trustworthy to rely on is quite beneficial.
  </p>
  <p>
  You're probably an introvert if you prefer to listen rather than speak, don't want to be the focus of attention, and prefer to work alone rather than in groups. Introverts make up at least a third of the population.
  </p>
  <p>
  This blog will help you get the most out of social and professional circumstances and interactions if you are an introvert or know someone who is. So let's get this party started!
  </p>
  <p>
  Introverts are those who would instead think rather than speak. They require alone time, avoid loud social situations, and often feel wrong about their less welcoming demeanor. Our Western society is unquestionably pro-extroverted.
  </p>
  <p>
  People are encouraged to speak up, have their voices heard, and leave their mark on history. Introverts, on the other hand, have discreetly taken their seats.
  </p>
  <p>
  Although a person might be shy and introverted simultaneously, the two traits do not usually go hand in hand. There are no hard and fast rules when it comes to introversion and extroversion. You can have both features and react differently in different situations.
  </p>
  <p style="text-align: center;">
  <b><em>Being an introvert is entirely natural; it is frequently serene and concentrated, but it can also be exhausting.</em></b>
  </p>
  <p>
  Introverts like many of the same activities as extroverts, participate in many of the same hobbies, and enjoy spending time with their friends (yeah!).
  </p>
  <p>
  However, introverts do experience emotions that extroverts do not. And one of them, alone time, is crucial to understanding what it's like to be an introvert.
  </p>
  <ul>
    <li>
    <b>When introverts are alone, they feel at ease.</b>
    <p>
    It's not dull, lonely, or empty when you're alone. An introvert's deepest feelings are felt while they are alone, contemplating, fantasizing, or thinking. To an introvert, even primary solo pursuits like reading or drawing feel rejuvenating.
    </p>
    <p>
    Alone time is not "filler" for an introvert. It's calm and soothing.
    </p>
    </li>
    <li>
    <b>Introverts, too, have different feelings about socializing.</b>
    <p>
    Social interaction does not always feel good to an introvert. It can be enjoyable, but it can also be draining. Like, certain activities are more exhausting than others, such as:
    </p>
    <ol>
      <li>Loud environments are more exhausting than calm ones.</li>
      <li>Chatting to strangers is more complex than talking to individuals you already know.</li>
    </ol><br/>
    <p>
    These activities — or any other stimulating social activity — will soon wear an introvert down, and they will become tired and burned out far before extroverts.
    </p>
    </li>
    <li>
    <b>Dopamine's Impact on Introverts.</b>
    <p>
    Introverts are more sensitive to dopamine than extroverts. As a result, individuals acquire a sense of reward and satisfaction quickly and with little stimuli.
    </p>
    <p>
    That's why, for an introvert, a peaceful afternoon with a book might be enthralling, but for an extrovert, it can be soul-crushing. Meanwhile, the kind of loud setting that extroverts thrive in overstimulates introverts to the point where they don't even enjoy it.
    </p>
    </li>
  </ul>
  <p>
  It's important to remember that being an introvert is perfectly normal, and each introvert is unique. One person may require somewhat more or less time alone than another.
  </p>
  <p>
  When you're among them, some may be incredibly outgoing, while others may be reticent. On the other hand, introverts share the same basic wiring as everyone else, and it doesn't feel any different than having any other healthy personality attribute.
  </p>
  <p style="text-align: center;">
  Do you consider yourself to be an introvert? If yes, then you must checkout <a href="https://letsgtok.com" target="_blank" style="color: #6930DB !important;"><b>LetsGtok</b></a>
  </p>
  </div>`
}

export default blog;
