import React, { useEffect } from "react";
import { QUERIES } from "constants/faq";

const ParentComponent = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  return (
    <>
		<div className="container">
      <h1 className="text-center pt-5">FAQ</h1>
      {
        QUERIES.map((obj, objIdx) => (
          <div className="row" key={`${obj.key}_${objIdx}`}>
            <div className="col-12 col-sm-4 col-md-3 mt-5 mb-sm-5">
               <h3 className="text-center pt-sm-2">{obj.key}</h3>
            </div>
            <div className="col-12 col-sm-8 col-md-9 my-sm-5">
          	  <div className="">
          			{
									obj.values[0] && obj.values.map((que, queIdx) => (
										<div key={queIdx}>
          						 <input type="checkbox" id={`question_${objIdx}_${queIdx}`} name="q" className="questions"/>
          						 <div className="d-flex flex-row align-self-center">
          							 <label htmlFor={`question_${objIdx}_${queIdx}`} className="question">
          							  <div className="plus">+</div> &nbsp; {que.question}
          							 </label>
          						 </div>
          						 <div className="answers" dangerouslySetInnerHTML={{__html: que.answer}}>
          					  </div>
										</div>
									))
								}
          		</div>
          	</div>
            <hr className='d-none d-sm-block'/>
          </div>
        ))}
    </div>
	  </>
   );
};

export default ParentComponent;
