import React from 'react'

function SectionFourComponent() {
  return (
    <div>
      <div className="section-four-wrapper d-none d-sm-block">
        <div className="row justify-content-evenly mx-5">
          <div className="col belief-paragraph lh-base my-auto">
            <p>
              “We believe feelings are the core of what makes us different from other individuals and we want to experience yours.”
            </p>
          </div>
          <a href="https://app.letsgtok.com/app/create_post" target="_blank" rel="noopener noreferrer" className="col lh-base">
            <img className="create_post" src={require("assets/svgs/create_post.svg").default} alt="create_post" />
          </a>
        </div>
      </div>
      <div className="section-four-mobile-wrapper mobile d-sm-none">
        {/* <div data-aos="zoom-in-up" data-aos-duration="1000" className="col-10 last-paragraph-text-mobile align-items-center mx-auto text-center lh-base">
              <p >
                  “We believe feelings are the core of what makes us different from other individuals and we want to experience yours.”
              </p>
          </div> */}
        <div className="col-10 belief-paragraph-mobile justify-content-center align-items-center text-center lh-base mx-auto mb-5">
          <p>
            “We believe feelings are the core of what makes us different from other individuals and we want to experience yours.”
          </p>
        </div>
        <a href="https://app.letsgtok.com/create_post" target="_blank" rel="noopener noreferrer" className="col-10 mx-2">
          <img className="create_post_mobile mx-1 mt-4 mb-5" src={require("assets/svgs/create_post_mobile.svg").default} alt="create_post" />
        </a>
      </div>
    </div>
  )
}

export default SectionFourComponent
