export const feelings = [
  {
    title: 'Confused',
    imageUrl: 'https://storage.googleapis.com/gtok-web.appspot.com/assets/images/other_LetsGtok.png',
    altText: 'confused, other, feeling, feelings, letsgtok',
    tradePrice: '$0.99'

  },
  {
    title: 'Excited',
    imageUrl: 'https://storage.googleapis.com/gtok-web.appspot.com/assets/images/special_LetsGtok.png',
    altText: 'excited, surprise, feeling, feelings, letsgtok',
    tradePrice: '$5.15'
  },
  {
    title: 'Current Feeling',
    imageUrl: 'https://storage.googleapis.com/gtok-web.appspot.com/assets/images/current_feeling_LetsGtok.png',
    altText: 'current feeling, feelings, letsgtok',
    tradePrice: '$10.99'
  },
  {
    title: 'Happy',
    imageUrl: 'https://storage.googleapis.com/gtok-web.appspot.com/assets/images/happy_LetsGtok.png',
    altText: 'happy, feeling, feelings, letsgtok',
    tradePrice: '$15.75'
  },
  {
    title: 'Surprise',
    imageUrl: 'https://storage.googleapis.com/gtok-web.appspot.com/assets/images/surprise_LetsGtok.png',
    altText: 'surprise, feeling, feelings, letsgtok',
    tradePrice: '$21.25'
  }
]
