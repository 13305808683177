const blog = {
  name: 'reminiscing-on-our-lives',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/reminiscing-on-our-lives_LetsGtok.jpg',
  title: 'Reminiscing on our lives',
  date: '11 Dec 2021',
  meta_description: 'We all have a habit of reminiscing about the past. Relieving these memories brings back anecdotes that remind us of how lovely, funny, dumb, and memorable those moments were.',
  meta_keywords: 'reminiscing,memories,childhood,parents,grandparents,grandpa,feelings,emotions,childhood memories',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  body: `<div>
  <p>
  Sonali is wondering something that many people do daily: <b>"<em>reminiscing.</em>"</b>
  </p>
  <p>
  Because you may not be familiar with this term, Sonali will clarify it in a few words:
  </p>
  <h6 class="text-center">
    <strong>“Reminiscing is the act of recalling hazy and sickly memories”</strong>
  </h6>
  <p>
  We all have a habit of reminiscing about the past.
  </p>
  <p>
  When we see any random television episode or hear that old music on the radio that takes us back to our childhood or share a story with our parents or grandparents. It's also usual to recall only several anecdotes from the triggering event.
  </p>
  <h6 class="text-center">
    <strong>“Our memories aren't perfect, and they like to play tricks on us”</strong>
  </h6>
  <p>
  Let me share some of my favorite shows, games, and snacks from my childhood 😄
  </p>
  <p>
  I recall how happy I used to feel watching Sanju draw whatever he wanted with the help of that magical pencil when I was watching <b>"<em>Shakalaka boom boom</em>"</b>. I used to think that if I had this pencil, I'd buy myself all kinds of silly things, mainly chocolates, haha! just to satisfy my sweet tooth 😜
  </p>
  <p>
  Growing up I used to watch <b>"<em>Noddy</em>"</b> and <b>"<em>Oswald</em>"</b> so much! Thinking just how great and fun their lives are; they don't have to study and can go around and have as much fun as they want, made me a little bit jealous of them.
  </p>
  <p>
  But remembering all of this now makes me realize how naive I was. I mean, it's not just me; I'm sure everyone felt in a similar way when they were kids.
  </p>
  <p>
  Nowadays, we can find a variety of snacks, such as <b>"<em>Kinder Joy</em>"</b>, which is simply an expensive egg with a minor chocolate filling and a toy. But back in our day, there used to come this cigarette-type candy, which I'm sure everyone bought, just to pretend smoking it to look "cool" (I know because I did it😂). It was really the random stupid shit of the time.
  </p>
  <p>
  Guess who was the complan girl back in the day? Me! 😎<br/>
  Having <b>"<em>Complan</em>"</b> in the morning before going to school was a daily ritual. Realizing that I'd be tall after drinking this, but who knew that these are just marketing tactics used by companies to attract children.
  </p>
  <p>
  These random things have made our childhood so lively and enjoyable that the modern technological era cannot match.
  </p>
  <p>
  I wish we could go back in time just to take in a little more of this random stupid stuff.
  </p>
  <p>
  Relieving these memories brings back anecdotes that remind us of how lovely, funny, dumb, and memorable those moments were.
  </p>
  <p>
  Those moments spent in the lawn playing <b>"<em>Beyblade</em>"</b> with our childhood buddies in the WOK and flaunting about that, hey! I have got the best Beyblade. These things seem little while we live in them, yet they become precious once we leave them and return to the real world.
  </p>
  <p>
  The actual value is shown when old memories, aka "childhood memories," are gone, and we no longer can enjoy that pure happiness.
  </p>
  <p>
  But we can also have fun with it! The appropriate stimuli can restore long-forgotten memories that once offered us great joy.
  </p>
  <p>
  But what is it about reminiscing that we find so pleasurable?
  </p>
  <p>
  The joy of reminiscing about our past is the joy of rediscovering... <b>"<em>OURSELVES.</em>"</b>
  </p>
  <p>
  Since you'll be able to immerse yourself in a familiar environment where you were in the thick of things.
  </p>
  <p>
  It's really something to be at ease with the times we live in. If you're feeling a little left out because of this fast-paced world, bringing your memories to the surface will make you happy.
  </p>
  <p>
  When a grandpa tells his grandkids about his childhood recollections, he will be happy to see them captivated by aspects that were quite normal in his day. This would motivate him to search his memory for buried bits of his life that he believed he had forgotten.
  </p>
  <p class="text-center">
  Trust me on this <b>"<em>OLD is really GOLD,</em>"</b> and so are our past memories.
  </p>
</div>`
}

export default blog;
