const blog = {
  name: 'new-year-new-me',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/new-year-new-me_LetsGtok.jpg',
  title: 'New Year, New ME!',
  date: '01 Jan 2022',
  meta_description: 'Even though I was born and raised in India, my tastes have always been international...',
  meta_keywords: 'christmas, personal, feelings, share, thoughts, experiences, festival',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  body: `<div>
  <p>
  We've arrived at the time of the year when everyone says, "New year, New ME." Every year, many people make resolutions to better themselves somehow, but most of them barely endure until February.
  </p>
  <p>
  But! This time you are going to pick a goal that you can and want to stick to throughout the year. Here is a list of our best advice for making a prosperous new year's resolution.
  </p>
  <h4 class="text-center my-4">Choose something you're passionate about</h4>
  <p>
  According to the adage, you will never work a day in your life if you do something you love.
  </p>
  <p>
  So why not make the exact resolution for the new year?
  </p>
  <p>
  Working for a goal that you are enthusiastic about will make your life much easier to manage.
  </p>
  <p>
  It's a lot simpler to put in the hard yards when you sincerely care about the ultimate result, whether it's taking better care of your health, getting that promotion, changing careers, or simply putting more effort into your relationships.
  </p>
  <h4 class="text-center my-4">Make it your reality</h4>
  <p>
  While it's good to have goals and ambitions in life, most of them will take time to realize. But, if you set your goals too high, your resolution will appear unattainable and more challenging to keep.
  </p>
  <p>
  Make a list of the measures you'll need to take to achieve your vast objective and break them down into smaller, more manageable goals. Instead of feeling frightened and guilty, you will feel more at ease and satisfied with your resolution.
  </p>
  <p>
  For example, you might not be able to make a career move in a year, but you might be able to begin or finish some study to assist you in getting there.
  </p>
  <h4 class="text-center my-4">Get through with the proper support</h4>
  <p>
  It's always a good idea to enlist the help of your loved ones, no matter how big or tiny your resolution may be. In any endeavor, having the support of your family and friends will help you focus and motivate yourself and keep you from feeling alone on your trip.
  </p>
  <p>
  Another excellent strategy for sticking to your resolution is seeking appropriate external assistance.
  </p>
  <p>
  Looking for better health? Make an appointment with a dietician.
  </p>
  <p>
  Do you need some time to focus on your relationships? Make an appointment with a counselor.
  </p>
  <p>
  Are you planning on doing more research? Seek out the resources that your institution has to offer.
  </p>
  <p>
  Don't let another year go by without keeping your resolutions.
  </p>
  <p>
  You might eventually land yourself a resolve that stays if you select a feasible goal that you are passionate about and have the support of your family and friends.
  </p>
  <p>
  If you're seeking new friends or a safe place to express your true sentiments, check out our share feelings section and join our no-judgment community.
  </p>
  <p>
  On this note, Let’s make some rock-solid resolutions and start the new year with a bang. Cheers!
  </p>
</div>`
}

export default blog;
