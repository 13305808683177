import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ParentComponent = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  return (
		<div className="container careers-wrapper">
			<h3 className="page-title">Careers</h3>
			<div>Lets change the world together.</div>
			<hr/>
			<div>
			Do you want to be without a mission for the rest of your life, or do you want to come work with us and change the world? <br/><br/>
			Lets Gtok is looking for creative, beautiful, innovative brains to come together for one common vision; to attain the unattainable goal of making people more &apos;human&apos; and the world a kinder place.<br/><br/>
			It is not a 9-5 job, it is a passion. For a person who craves variety, welcomes high levels of collaboration, and has an enterprising attitude, we are the perfect fit. <br/><br/>
			Lets Gtok is the world&apos;s only digital asset platform to share and trade feelings.
			</div>
			<hr className="divider"/>
			<h4 className="page-title">Available positions</h4>
			<p>
				All of our current positions are in India. Based on the role and responsibilities, you may be required to travel for meetings on occasion.
			</p>
			<ul className="section pt-3">
				<li className='section-title'>
					<div className='d-flex flex-row justify-content-between'>
						<div>
						Catalysts &nbsp;<small className='d-none'><a href="assets/jobs/content_writer_intern.txt" download className="text-violet">Specifications</a></small>
						</div>
						<Link to='/catalysts' className='btn btn-link text-violet'>
							Apply here
						</Link>
					</div>
				</li>
				<li className="section-title">
					<div className='d-flex flex-row justify-content-between'>
						<div>
						Junior Full Stack Developer Intern &nbsp;<small><a href="assets/jobs/junior_developer.txt" download className="text-violet">Specifications</a></small>
						</div>
						<div className=''>
							<button className='btn btn-link text-violet'>Closed</button>
						</div>
					</div>
				</li>
				<li className='section-title'>
					<div className='d-flex flex-row justify-content-between'>
						<div>
						Content Writer Intern &nbsp;<small><a href="assets/jobs/content_writer.txt" download className="text-violet">Specifications</a></small>
						</div>
						<div className=''>
							<button className='btn btn-link text-violet'>Closed</button>
							<a href="https://www.linkedin.com/jobs/view/2663275419" target="_blank" rel="noreferrer" className='btn btn-link d-none'>Apply here</a>
						</div>
					</div>
				</li>
				<li className='section-title'>
					<div className='d-flex flex-row justify-content-between'>
						<div>
						Business Development Lead &nbsp;<small className='d-none'><a href="assets/jobs/content_writer_intern.txt" download className="text-violet">Specifications</a></small>
						</div>
						<div className=''>
							<button className='btn btn-link text-violet'>Opening soon</button>
						</div>
					</div>
				</li>
				<li className="section-title d-none">Social Media Marketing Ambassador &nbsp;<small><a href="assets/jobs/social_media_ambassador.txt" download className="text-violet">Specifications</a></small></li>
			</ul>
			<div>
				If there isn&apos;t an open position for you, don&apos;t worry! You can e-mail your resume to <a href="mailto:letsgtok@gmail.com" className="text-violet">letsgtok@gmail.com</a> and lets us know how you can help grow and build Lets Gtok in your way, why you would be a great addition to our team.
			</div>
		</div>
  );
};

export default ParentComponent;
