const blog = {
  name: 'fomo-in-this-century',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/fomo-in-this-century_LetsGtok.jpg',
  title: 'FOMO (fear of missing out)',
  meta_description: 'I\'m privileged as a 21st-century youngster. That is to say,...',
  meta_keywords: 'fomo,fear,fear of missing out,feelings,emotions,share',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  date: '03 Oct 2021',
  body: `<div>
  I'm privileged as a 21st-century youngster. That is to say, I've spent my entire life in front of a computer, with a variety of resources at my disposal. I had running water in my residence and a vehicle to travel to where I needed to go. My parents, for one, did. In addition, I had the benefit of receiving exceptional education and medical care when I needed it.
  <br/><br/>
  Web-based media, on the other hand, is something that shows up with these benefits and advancements. Our curiosity has perplexed us. It has shown us things that we would be fortunate not to see.
  <br/><br/>
  <div style='text-align: center'><b>What is FOMO? For what reason is it so significant?</b></div><br/>
  It's one of the most well-known conditions of the twenty-first century: the fear of missing out on a significant chance. FOMO affects the majority of people who use social media on the internet. They're always afraid of missing something — a special occasion, breaking news, any workplace action, or the promise of cake in the lounge.
  <br/><br/>
  The constant posting of our 'greatest moments' has made this generation feel a constant sense of FOMO. We should not be doing anything by any stretch of the imagination if we aren't doing something that satisfies what every one of our companions is posting.
  <br/><br/>
  We are so afraid of missing out on anything that is going on that we lose track of ourselves. We are experiencing a loss of regularity's complexities.
  <br/><br/>
  I believe it is past time for all of us to take a vacation. Focus on what's in front of you rather than what's behind a screen.
  <br/><br/>
  <b>Continue reading…</b><br/><br/>
  When we hear about FOMO, the first image that comes to us is of a swarm of young people walking around with cell phones in their hands.
  <br/><br/>
  Indeed, 45 percent of people who suffer from FOMO can't spend more than 12 hours without checking their social media accounts.
  <br/><br/>
  <h5 style='text-align: center;'><strong>Convert FOMO to JOMO (joy of missing out)</strong></h5><br/>
  If FOMO causes a loss of productivity, there should be some JOMO on the earth that can transform Fear into Joy. Don't you think so?<br/><br/>
  <strong>How to Handle FOMO?</strong><br/><br/>
  <ul>
    <li>Enjoy the sensation of being out of the loop. <br/>
    Some great things are occurring out there, and you aren't always invited, and that there's nothing you can do about it.
    </li><br/>
    <li>Take a break from social media for a while. <br/>
    Try going without the internet for a day, a week, or even a month, and discover the wonders of the real world.
    </li><br/>
    <li>Remove social networking apps from your phone. <br/>
    It's not as drastic as quitting Facebook entirely, but it's a quick and relatively painless approach to limit your social media usage when you're not at your computer.
    </li><br/>
    <li>Get a detox.<br/>
    The rules are straightforward: no work discussion, watches, outside food, booze or drugs, and, of course, no digital equipment.
    </li><br/>
  </ul>
  Keep in mind that FOMO is becoming increasingly limitless, which might be hazardous to you overall. It causes an overabundance and there are some measures you may do to avoid FOMO in your surroundings. Keep in mind that efficiency is an essential component of progress.<br/><br/>
  <div style='text-align: center;'>
    <strong>FOMO is a result of unhappiness.</strong><br/><br/>
    Are you stuck in the FOMO cycle? <br/>
    You're probably not overjoyed with your situation. <br/>
    Unhappiness is a common source of FOMO, so put everything aside and turn your FOMO into JOMO at <a href="https://letsgtok.com" target="_blank" style="color: #6930DB !important;">LetsGtok</a>
  </div>
</div>`
}

export default blog;
