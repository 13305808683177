import React, { useState } from 'react'
import { firestore } from 'config/firebase';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API } from 'constants/env_keys';

function SectionFiveComponent() {
    const [loading, setLoading] = useState(false);
    const [subscribeEmail, setSubscribeEmail] = useState("");

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const user = await firestore.collection('newsletters').where('email', '==', subscribeEmail).get();

        if (!user.size) {
            firestore.collection('newsletters').add({
                email: subscribeEmail,
                subscribe: true,
                createdAt: new Date(),
            });

        } else {
            const id = user.docs.map(d => d.id);
            firestore.collection('newsletters').doc(id[0]).update({
                subscribe: true,
                updatedAt: new Date(),
            });
        }
        await axios.post(
            API.BASE_URL + '/subscribe',
            {
                email: subscribeEmail,
                mailSubject: 'Thank you for signing up to our newsgroup. We\'ve put you at the top of our priority list.',
            },
            {
                headers: {
                    'content-type': 'application/json',
                },
            },
        );
        setSubscribeEmail('');
        toast.success("🚀 Thank you for joining our newsgroup!");
        setLoading(false);
    }

    return (
        <div>
            <div className="section-five-wrapper d-none d-sm-block">
                <div className="d-none d-sm-block">
                    <div className="d-flex justify-content-center">
                        <div className="newsletter-section justify-content-center">
                            <img src={require("assets/svgs/newsletter_background.svg").default} alt="newsletter" className="newsletter-bg" />
                            <div className="row">
                                <div className="col-4 newsletter-overlay-text mt-4">
                                    <p className="newsletter-overlay-text-para mt-5">
                                        Become the first one to hear from us!
                                    </p>
                                    <form onSubmit={submitHandler} className="newsletter-form mt-5">
                                        <input type="email" placeholder="Enter your e-mail id" onChange={e => setSubscribeEmail(e.target.value)} value={subscribeEmail} className="input-email-box" />
                                        <button type="submit" className="newsletter-submit-button btn btn-violet" onClick={submitHandler} disabled={loading}>{loading ? "Subscribing..." : "Subscribe"}</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="section-five-mobile-wrapper d-sm-none mobile mt-5">
                <div className="d-sm-none section-three">
                    <div className="d-flex justify-content-center">
                        <div className="newsletter-section-mobile">
                            <img src={require("assets/svgs/newsletter_bg_mobile.svg").default} alt="newsletter" width="100%" className="newsletter-bg-mobile" />
                            <div className="row justify-content-center">
                                <div className="col newsletter-overlay-text-mobile">
                                    <p className="newsletter-overlay-text-para-mobile">Become the first one to hear from us!
                                    </p>

                                    <form className="newsletter-form-mobile">
                                        <input type="email" placeholder="Enter your e-mail id" onChange={e => setSubscribeEmail(e.target.value)} value={subscribeEmail} className="input-email-box-mobile" />
                                        <button type="submit" className="newsletter-submit-button-mobile btn btn-violet" onClick={submitHandler} disabled={loading}>{loading ? "Subscribing..." : "Subscribe"}</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SectionFiveComponent
