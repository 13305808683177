import React from 'react';

function SectionThreeComponent() {

    return (
        <div>
            <div className="map-section-wrapper d-none d-sm-block">
                <div className="d-none d-sm-block">
                    <div className="d-flex justify-content-center">
                        <div className="purple-background-with-content">
                            <img src={require("assets/images/purple_background.png").default} alt="background 1" className="purple-background-desktop" />
                            <div className="row align-items-center">
                                <div className="col-md-12 purple-background-overlay-text">
                                    <p className="purple-background-overlay-text-para mt-5">Unbottle  your <span className="purple-background-bold-text">feelings </span> in  a  world <br />
                                        where they are <span className="purple-background-bold-text">valued  </span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-10 section-three-wrapper d-none d-sm-block">
                                <img className="map" src={require("assets/svgs/world-map.svg").default} alt="1" />

                                <div className="col-12 d-flex justify-content-evenly">
                                    <div className="col-sm-1 feelings_on_map">
                                        <img id="feeling_1_on_map" src={require("assets/svgs/small_1.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-1 feelings_on_map">
                                        <img id="feeling_2_on_map" src={require("assets/svgs/small_2.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-2 feelings_on_map">
                                        <img id="feeling_3_on_map" src={require("assets/svgs/med.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-6 feelings_on_map">
                                        <img id="feeling_4_on_map" src={require("assets/svgs/big_1.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-6 feelings_on_map">
                                        <img id="feeling_5_on_map" src={require("assets/svgs/big_2.svg").default} alt="1" />
                                    </div>

                                </div>
                            </div>


                            <div className="col-10 section-three-wrapper text-center align-items-center d-none d-sm-block">
                                <a href="https://app.letsgtok.com" target="_blank" rel="noopener noreferrer" className="btn btn-violet-outline mx-auto">Start Exchanging</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
            <div className="map-section-mobile-wrapper d-sm-none mobile">
                <div className="d-sm-none section-three">
                    <div className="d-flex justify-content-center">
                        <div className="purple-background-with-content">
                            <img src={require("assets/images/purple_background_mobile.png").default} alt="background 1" width="100%" className="purple-background" />
                            <div className="row align-items-center justify-content-center">
                                <div className="col align-self-center purple-background-overlay-text text-center">
                                    <p className="purple-background-overlay-text-para">Unbottle  your <span className="purple-background-bold-text">feelings </span> in  a  world <br />
                                        where they are <span className="purple-background-bold-text">valued  </span>
                                    </p>
                                </div>
                            </div>


                            <div className="section-three-wrapper">
                                <img className="map col align-self-center" src={require("assets/svgs/world_map_mobile.svg").default} alt="1" />
                                <div className="col-12 d-flex">
                                    <div className="col-sm-10 feelings_on_map_mobile">
                                        <img id="feeling_1_on_map_mobile" src={require("assets/svgs/feeling_1_mobile_view.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-10 feelings_on_map_mobile">
                                        <img id="feeling_2_on_map_mobile" src={require("assets/svgs/feeling_2_mobile_view.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-10 feelings_on_map_mobile">
                                        <img id="feeling_3_on_map_mobile" src={require("assets/svgs/feeling_3_mobile_view.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-10 feelings_on_map_mobile">
                                        <img id="feeling_4_on_map_mobile" src={require("assets/svgs/feeling_4_mobile_view.svg").default} alt="1" />
                                    </div>

                                    <div className="col-sm-10 feelings_on_map_mobile">
                                        <img id="feeling_5_on_map_mobile" src={require("assets/svgs/feeling_5_mobile_view.svg").default} alt="1" />
                                    </div>

                                </div>
                            </div>

                            <div className="col align-self-center section-three-wrapper  text-center align-items-center mx-auto">
                                <a href="https://app.letsgtok.com" target="_blank" rel="noopener noreferrer" className="btn btn-violet-outline mx-auto ">Start Exchanging</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SectionThreeComponent
