import React from "react";
import SectionTwoComponent from './section_two/component';
import SectionThreeComponent from "./section_three/component";
import SectionFourComponent from './section_four/component';
import SectionFiveComponent from './section_five/component';
import SwiperCore, { Navigation, Scrollbar, A11y, Parallax, Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { feelings } from 'constants/home/categories';

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
// install Swiper modules
SwiperCore.use([Navigation, Scrollbar, A11y, Parallax, Autoplay, EffectCoverflow]);

function HomeComponent() {
  return (
    <div className="container-fluid">
      <div className="section-one-wrapper">
        <div className="row align-items-center">
          <div className="col-12 left-block text-center">
            <h1 className="header d-none d-sm-block">
              Feelings are assets <br />
            </h1>

            <p className="col-10 center-block description text-sm-center mt-3 d-none d-sm-block" id="center-block-para">
              An emotional platform to stay connect with emotions <br /> and to lead a healthy lifestyle.
            </p>

            <a href="https://app.letsgtok.com" target="_blank" rel="noopener noreferrer" className="btn btn-violet-v3 d-none d-sm-inline-block mt-2">Share now</a>
          </div>
        </div>
      </div>

      <div className="col-12 section-one-people-wrapper justify-content-center d-none d-sm-flex">
        <img className="img-base" src={require("assets/svgs/box.svg").default} alt="0" width="100%" />
        <div className="row align-items-end">
          {
            feelings?.map((feeling, idx) => (
              <div className="col" key={idx}>
                <img src={feeling.imageUrl} alt={feeling.altText} width="100%" className="img-fluid" />
                <div className="img-feeling">{feeling.title}</div>
              </div>
            ))
          }
        </div>
      </div>

      {/* for mobile view  */}
      <div className="section-one-people-mobile-wrapper mobile d-sm-none mt-2">
        <div className="swiper-container-fluid">
          <Swiper
            spaceBetween={12}
            slidesPerView={2}
            speed={1000}
            autoplay={{
              "delay": 1200,
              "disableOnInteraction": false
            }}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              "rotate": 0,
              "stretch": 8,
              "depth": 200,
              "modifier": 2,
              "slideShadows": false
            }}
            navigation={true}
            loop={true}
            scrollbar={{ draggable: true }}
            className="slider"
          >
            {
              feelings?.map((feeling, idx) => (
                <SwiperSlide className="swiper-slide" key={idx}>
                  <img src={feeling.imageUrl} alt={feeling.altText} className="img-fluid" />
                  <div className="img-feeling">{feeling.title}</div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>

      </div>

      <div className="section-one-people-mobile-wrapper align-items-center text-center mobile justify-content-center d-sm-none">
        <div className="col-12 left-block text-center mt-5">
          <h1 className="header">
            Feelings are assets <br />
          </h1>
        </div>
        <p className="col-10 center-block description mx-auto" id="center-block-para">
          An emotional platform to stay connect with emotions <br /> and to lead a healthy lifestyle.
        </p>

        <a href="https://app.letsgtok.com" target="_blank" rel="noopener noreferrer" className="btn btn-violet-v3 mb-5 mt-2">Share now</a>
      </div>

      <SectionTwoComponent />
      {/* Images and descriptions for Share and Trade Feelings */}

      <SectionThreeComponent />
      {/* Map section starts here */}
      {/* Join button goes here */}

      <SectionFourComponent />
        {/* Belief paragraph and create post banner */}

      <SectionFiveComponent/>
        {/* Newsletter section */}

    </div>
  );
}

export default HomeComponent;
