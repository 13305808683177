const blog = {
  name: 'e-for-empathy',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/e-for-empathy_LetsGtok.jpg',
  title: '"E" for Empathy & Elephants',
  date: '18 Sep 2021',
  meta_description: 'Can you recall such instances when you see someone else suffering...',
  meta_keywords: 'empathy,elephants,feelings,share',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/letsgtok/' target='_blank' style='color: #6930DB !important;'>Zena</a></div>`,
  body: `<div>
  <h4>What Is Empathy?</h4><br/>
  Can you recall such instances when you see someone else suffering, and you quickly imagine yourself in their shoes and feel compassion for what they are going through? Well, this is what empathy looks like.
  <br/><br/>
  To put it clearly into words, empathy is the capacity to genuinely get what others feel, see things according to their perspective, and envision yourself in their place.
 <br/><br/>

 These are some of the signs you can look for if you're an empathetic person
 <br/><br/>
  <ul>
    <li>You are acceptable at truly paying attention to what others need to say.</li>
    <li>Individuals frequently inform you regarding their issues. </li>
    <li>You are acceptable at getting on how others are feeling.</li>
    <li>You frequently ponder how others feel.</li>
    <li>Others come to you for guidance.</li>
    <li>You frequently feel overpowered by appalling occasions.</li>
    <li>You attempt to help other people who are languishing.</li>
    <li>You care profoundly about others.</li>
  </ul>
  Having a lot of compassion makes you concerned about the happiness and prosperity of others. It also implies that you can become overburdened, exhausted, or even overwhelmed if you are constantly thinking about the feelings of others.
  <br/><br/>
  <h4 class="text-center"><strong>EMPATHY AND ELEPHANTS </strong> </h4>
  <div class="text-center"><b>It's Time to Accept; Elephants Are Empathetic Beings, Just Like Us</b></div><br/>
  Empathy and emotions in elephants are well-understood. Seeing elephants in the wild is the greatest way to get a true sense of these magnificent creatures. In the wild, elephants are at their most awe-inspiring, and some of their behaviors demonstrate just how emotionally sophisticated they are.
  <br/><br/>
  Elephants react the same way to the other elephant's discomfort in the wild and in this confined study this is referred to as emotional contagion by scientists.
  <br/><br/>
  The elephants also rushed to their friend's side, gently touching them with their trunks to comfort them and making soft chirping sounds. According to the experts, elephants find it particularly soothing when one of their trunks is placed inside the other's mouth.
  <br/><br/>
  Elephant intelligence is sometimes neglected by people, although it is undeniable that they are extremely intelligent creatures. But how intelligent are elephants?
  <br/><br/>
  <ul>
    <li><strong>Animal with a high level of empathy</strong> <br/>
      Elephants are thought to be one of the most empathetic animals on the planet.
    </li>
    <br/>
    <li><strong>They have close social ties</strong><br/>
      Asian elephants are commonly assumed to be solitary. Their social lives are so complicated that understanding them has taken years of research, and we're still learning.
    </li>
  </ul>
  <blockquote>
  FUN FACT - Female Asian elephants have between 9 to 60 companions, although they may not see them for lengthy periods; instead, they interact with them chemically and acoustically.
  </blockquote>
  <ul>
    <li><strong>They are able to recognize themselves in the mirror</strong><br/>
      Elephants are considered to be one of the world's most intelligent mammals, even though they are extremely emotional and gregarious. The mirror test has been confirmed to be passed by Asian elephants. The animal is marked to see if they utilize the mirror to check their appearance.
    </li>
    <br/>
    <li><strong>Taking use of their surroundings</strong><br/>
      Elephants in Asia and Africa have been observed using various objects as tools to complete various activities. Elephants' ability to make the most of their surroundings and use imagination to fulfill various tasks demonstrates how smart and thoughtful these animals are.
    </li>
    <br/>
    <li><strong>However, not all elephants have the same level of liberty</strong><br/>
    Unfortunately, elephants utilized for tourist rides are unable to fully convey these emotions. Their lifestyles at these facilities are so dissimilar to what they should be that they are nearly a different species.
    </li>
    <br/>
  </ul>

  <div class="text-violet text-center"><b>We want to see elephants in their natural habitats, and we're collaborating with responsible travel providers to make that happen.</b></div>
</div>`
}

export default blog;
