import React, { useState } from 'react'
import { firestore } from 'config/firebase';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API } from 'constants/env_keys';

function SectionFiveComponent() {
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const submitHandler = async (e) => {
    if (!subscribeEmail) {
      toast.error("Please enter your email");
      return;
    }
    e.preventDefault();
    const user = await firestore.collection('newsletters').where('email', '==', subscribeEmail).get();
    
    if (user.size) {
      const id = user.docs.map(d => d.id);
      
      firestore.collection('newsletters').doc(id[0]).update({
        subscribe: false,
        updatedAt: new Date(),
      });
      await axios.post(
        API.BASE_URL + '/unsubscribe',
        {
          email: subscribeEmail,
          mailSubject: 'Hope we see you again in our newsgroup. Bye for now!',
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      setSubscribeEmail('');
      toast.success("Your email removed from our newsgroup!");
    } else {
      toast.error("Something went wrong. Try later!");
    }
  }

  return (
    <div className="container text-center mt-5">
      <h3>Unsubscribe to our newsgroup</h3>
      <div className="d-flex flex-column align-self-center">
        <input type="email" className='m-3' placeholder="Enter your email" onChange={e => setSubscribeEmail(e.target.value)} value={subscribeEmail}/>
        <button className='btn btn-violet m-3' onClick={submitHandler}>Unsubscribe</button>
      </div>
    </div>
  )
}

export default SectionFiveComponent