import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ParentComponent = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="container-fluid">
			<div className="catalysts-benefits-wrapper d-none d-sm-block">
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close" />
				</Link>
				<div className="text">
					<div className="row align-items-center">
						<div className="row">
							<div className="col-12 col-sm-6">
								<h5 className="header-key">Fantastic resume builder</h5>
								<p className="header-value">
									Being a catalyst will not only challenge you professionally but will also be a lot of fun. You will develop a variety of abilities, all while expanding your network.
								</p>
							</div>
							<div className="col-6"></div>
						</div>
						<div className="row">
							<div className="col-6"></div>
							<div className="col-12 col-sm-6">
								<h5 className="header-key">First to have front-end access</h5>
								<p className="header-value">
									Catalysts will be the first to investigate and implement the new features in the app, hence boosting their trading profits.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-sm-6">
								<h5 className="header-key">An adaptable schedule</h5>
								<p className="header-value">
									You can choose your hours and determine how much (or how little) you receive out of your time in the role.
								</p>
							</div>
							<div className="col-6"></div>
						</div>
						<div className="row">
							<div className="col-6"></div>
							<div className="col-12 col-sm-6">
								<h5 className="header-key">Expand your network</h5>
								<p className="header-value">
									Being a catalyst will help you expand your network by presenting you to a large number of individuals, which opens the door to future changes.
								</p>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-12 col-sm-6">
								<div>
									<h5 className="header-key">No prior experience is required</h5>
									<p className="header-value">
										We are just seeking active and outgoing individuals who would promote Lets Gtok on campus and social media. So, prior experience isn’t a necessity.
									</p>
								</div><br /><br /><br />
								<h1 className="header pt-5">Benefits</h1>
							</div>
							<div className="col-12 col-sm-6 ml-auto">
								<img src={require("assets/images/catalysts/benefits_1.png").default} alt="benefits_1" className="header-img" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="catalysts-benefits-wrapper mobile d-sm-none justify-content-center">
				<h1 className="header-mobile pt-4">Benefits</h1><br /><br /><br />
				<Link to="/catalysts">
					<img src={require("assets/svgs/catalysts_close.svg").default} alt="close" className="close-mobile" width="5%" />
				</Link>
				<div className="text text-center">
					<div className="row align-items-center">
			
							<div className="col-12 mb-5">
								<h5 className="header-key">Fantastic resume builder</h5>
								<p className="header-value">
									Being a catalyst will not only challenge you professionally but will also be a lot of fun. You will develop a variety of abilities, all while expanding your network.
								</p>
							</div>

			
				
							<div className="col-12 mb-5">
								<h5 className="header-key">First to have front-end access</h5>
								<p className="header-value">
									Catalysts will be the first to investigate and implement the new features in the app, hence boosting their trading profits.
								</p>
							</div>
					
			
							<div className="col-12 mb-5">
								<h5 className="header-key">An adaptable schedule</h5>
								<p className="header-value">
									You can choose your hours and determine how much (or how little) you receive out of your time in the role.
								</p>
							</div>
						
				
							<div className="col-12 mb-5">
								<h5 className="header-key">Expand your network</h5>
								<p className="header-value">
									Being a catalyst will help you expand your network by presenting you to a large number of individuals, which opens the door to future changes.
								</p>
							</div>
		
				
							<div className="col-12 mb-5">
								<div>
									<h5 className="header-key">No prior experience is required</h5>
									<p className="header-value">
										We are just seeking active and outgoing individuals who would promote Lets Gtok on campus and social media. So, prior experience isn’t a necessity.
									</p>
								</div>
			
							<div className="col-12 ml-auto">
								<img src={require("assets/images/catalysts/benefits_1.png").default} alt="benefits_1" className="header-img" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParentComponent;
