const blog = {
  name: 'what-happens-when',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/what-happens-when_LetsGtok.jpg',
  title: 'What happens when you don\'t express your Emotions?',
  meta_description: 'Its such a misunderstood concept when it comes to expressing...',
  meta_keywords: 'pain,emotions,life,overrated,expressions,metal,health,negativity',
  date: '04 Sep 2021',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/letsgtok/' target='_blank' style='color: #6930DB !important;'>Zena</a></div>`,
  body: `<div>
  <div class=text-center>
  EMOTIONS!!  <br/>
  It's such a misunderstood concept when it comes to expressing, <br/>
  BUT <br/>
  when it is to be heard, it becomes overrated.<br/>
  </div>
  <br/>

  Emotional pain is a part of life. Emotional tension can be difficult to manage. Pressure to succeed in our personal lives and at work can trigger a slew of negative feelings.
  <br/><br/>
  Many people do not have a good emotional relationship. And they frequently opt to suppress negative emotions since they don't know how to relate to or deal with them.
  <br/><br/>
  Instead of analyzing these feelings, most of us learn to avoid them or push them away subconsciously. Even when we do, though, it constantly persists. We all do it regularly: we hide our feelings. We block off the agony and bury our anxiety, dread, and rage.
  <br/><br/>
  Nobody likes to experience bad emotions like shame, trouble, or fury, but ignoring and avoiding how you're feeling might probably make things worse. If you ignore your unpleasant feelings, it can affect more than only your long-term health.
  <br/><br/>
  We all are so busy making our lives that feelings are often criticized. It is taught to keep emotions out. Some people strive to avoid expressing their emotions completely.
  <br/><br/>
  Suppressing your emotions, whether they are anger, sadness, grief, or irritation, can cause physical tension. Even though the basic feeling is different, the result is the same. It has been shown to have an impact on blood pressure, memory, and self-esteem.
  <br/><br/>
  These hidden and suppressed feelings are not recognized or followed up on - and while enthusiastic hiding may not appear to be harmful, it can pose a major health risk.
  <br/><br/>
  This is what occurs when you don't allow yourself to fully experience your emotions.
  <br/><br/>
  <ul>
  <li>Mental exhaustion</li>
  <li>Problems with the stomach. </li>
  <li>Headaches and pains in the brain.</li>
  <li>Negative emotions that are more anchored.</li>
  <li>Put on weight.</li>
  <li>Having difficulty finding positive things in regular life.</li>
  </ul>
  <br/>
  We as a team believe that sharing equals caring. We have the best place for our users who wish to express their negative feelings and have a wonderful positive atmosphere.<br/>
  <div class="text-center mt-4">
  <a href="https://app.letsgtok.com/app/create_post" target="_blank"><button class="btn btn-violet-v2 btn-rounded">Start sharing</button></a>
  </div>
  Why stress when life is so short? Let us live each day as if it were our last, focusing on the positive aspects of life.

  <br/><br/>
  You may also read our other blog, <a href="/blogs/share-feelings" style="color: #6930db !important;">"Why sharing feelings is important,"</a> to gain a better picture of how things work at LetsGtok and how we help people in sharing their sentiments.
</div>`
}

export default blog;
