import React, { useState } from 'react';
import '../../assets/css/contact_us.css';
import { toast } from 'react-toastify';
import { firestore } from '../../config/firebase';
import * as yup from 'yup';
import axios from 'axios';
import { API } from 'constants/env_keys';

const validateForm = async contactDetails => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
  });
  return await schema.isValid(contactDetails);
};

const formDetails = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const ContactForm = () => {
  const [contactDetails, setContactDetails] = useState({
    ...formDetails,
  });

  const [loading, setLoading] = useState(false);

  const submitForm = async () => {
    try {
      setLoading(true);
      if (!(await validateForm(contactDetails))) {
        throw new Error('Invalid Form Details');
      }

      const user = await firestore.collection('queries').add({
        name: contactDetails.name,
        email: contactDetails.email,
        subject: contactDetails.subject,
        message: contactDetails.message,
        createdAt: new Date(),
      });
      await axios.post(
        API.BASE_URL + '/submit',
        {
          name: contactDetails.name,
          email: contactDetails.email,
          subject: contactDetails.subject,
          message: contactDetails.message,
          mailSubject: 'Thank you for contacting us. We\'ll get back to you shortly!',
          mailId: API.MAIL_ID,
          mailPass: API.MAIL_PASS,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      if (!user) {
        throw new Error('Error submitting Form Details 😵');
      }
      setLoading(false);
      toast.success("🚀 We've received your request. Hold on, we'll be contacting you soon");
      setContactDetails(formDetails);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="container">
      <div className="row mt-5 text-start">
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-stretch">
          <h1 className="pb-3">Contact Us</h1>
          <form>
            <div className="mb-3">
              <label htmlFor="contactEmail" className="form-label">
                Email*
              </label>
              <input
                type="email"
                value={contactDetails.email}
                className="form-control"
                required
                onChange={e => {
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  });
                }}
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactName" className="form-label">
                Name*
              </label>
              <input
                value={contactDetails.name}
                type="text"
                className="form-control"
                required
                onChange={e => {
                  setContactDetails({
                    ...contactDetails,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactSubject" className="form-label">
                Subject*
              </label>
              <input
                value={contactDetails.subject}
                type="text"
                className="form-control"
                required
                onChange={e => {
                  setContactDetails({
                    ...contactDetails,
                    subject: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactMessage" className="form-label">
                Message*
              </label>
              <textarea
                value={contactDetails.message}
                type="text"
                required
                className="form-control"
                onChange={e => {
                  setContactDetails({
                    ...contactDetails,
                    message: e.target.value,
                  });
                }}
              />
            </div>
            <button
              type="submit"
              className="btn btn-submit"
              onClick={async e => {
                e.preventDefault();
                await submitForm();
              }}
            >
              {loading ? <div className="spinner-border text-light" role="status"></div> : 'Submit'}
            </button>
          </form>
        </div>
        <div className="col-md-6 my-5 my-sm-0 d-flex flex-column justify-content-center align-items-center">
          <img alt="letsgtok, gtok, feelings, contact" className="col-8" src={require(`assets/images/contact_us.png`).default} />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
