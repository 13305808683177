import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

let config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

export const app = firebase.initializeApp(config);
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export const getQuery = (customQuery = null) => {
  return customQuery.then((results) => {
    const object = []
    results.forEach(doc => {
      const data = doc.data();
      let docObj = {
        id: doc.id
      }
      Object.keys(data).forEach(key => {
        docObj[key] = data[key]
      })
      object.push(docObj);
    })
    return object.sort((a, b) => a.createdAt - b.createdAt)
  })
  .catch((err) => {
    console.error(err)
    return [{ error: err.code }]
  })
}

export const add = (collection, data) => {
  data.createdAt = new Date().getTime()
  data.updatedAt = new Date().getTime()
  return firestore.collection(collection).add(data)
    .then(res => {
      return formatResult(200, 'Successfully created', {
        id: res.id,
        path: res.path
      })
    })
    .catch(e => formatResult(500, e.message))
}

export const remove = (collection, id) => {
  return firestore.collection(collection).doc(id).delete()
    .then(() => formatResult(200, 'Deleted successfully'))
    .catch(er => formatResult(500, er.message))
}

/* Common code */
function formatResult(status, message, data={}) {
	return { status, message, data };
}

export default firebase;
