import React from "react";
import { Link } from "react-router-dom";

function Cards() {
    return (
        <div>
            <div className="section-two-wrapper d-none d-sm-block" >
                <div className="section mt-5">
                        <div className="container">
                            <div className="row justify-content-evenly">
                            <div className="col-4">
                                <div className="overlay-content img-hover-zoom">
                                    <img alt="share feelings, letsgtok" className="img-fluid" src="https://storage.googleapis.com/gtok-web.appspot.com/assets/images/share_feeling_LetsGtok.png"/>
                                    <div className="overlay-text">
                                        <p className="text">Share <br/> Feelings</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 d-flex align-items-center mt-4">
                                <div className="center-paragraph" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                                    <p><strong className="card-heading">Sharing your feelings has never been this easier before.</strong></p>
                                    <p className="margin-top-s card-content">
                                        Express your feelings in a judgement free environment,
                                        where they are mattered. Sharing is liberating at Lets Gtok.
                                        Even anonymously!
                                    </p>
                                    <Link to="/blogs/share-feelings" className="btn mt-2 btn-violet-v3">Learn More</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
            </div>

            <div className="section-two-wrapper mobile d-sm-none">

                <div className="col-10 overlay-content img-hover-zoom mt-5 mx-auto">
                    <div className="zoom-in">
                        <img alt="share feelings, letsgtok" className="img-fluid img-fluid-mobile mx-auto" src="https://storage.googleapis.com/gtok-web.appspot.com/assets/images/share_feeling_LetsGtok.png"/>
                    </div>

                    <div className="overlay-text">
                            <h1 className="card-text"><strong>Share <br/> Feelings</strong></h1>
                    </div>
                </div>

                <div className="text-center mt-5 mx-auto">
                    <div className="card-body" data-aos="fade-up"  data-aos-duration="1000">
                        <p className="card-title mt-3 mb-4"><strong className="card-heading">Sharing your feelings has never been this easier before.</strong></p>
                        <p className="card-text margin-top-s card-content mt-3 mb-4">Express your feelings in a judgement free environment,
                                        where they are mattered. Sharing is liberating at Lets Gtok.
                                        Even anonymously!
                        </p>
                        <Link to="/blogs/share-feelings" className="btn mt-2 btn-violet-v3 btn-violet-news mt-3">Learn More</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Cards
