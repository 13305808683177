const blog = {
  name: 'is-personal-data',
  category: 'Trading',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/is-personal-data_LetsGtok.jpg',
  title: 'Is Personal Data the Next Big Thing?',
  meta_description: 'Your data - your currency. What if you were...',
  meta_keywords: 'personal,data,currency,big,thing',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  date: '07 Nov 2021',
  body: `<div>
  <p style='text-align: center;'>
    <b>#Your data = #Your currency</b>
  </p>
  <p>
  What if you were compensated by social media companies like Facebook and Instagram? Several firms envision a future in which we are all the brokers of our own personal data, as well as the beneficiaries.
  </p>
  <p>
  Here at Lets Gtok, however, you will be compensated for your data; as we often say,
  </p>
  <p style='text-align: center;'>
    <b>data = assets</b>
  </p>
  <p>
   As a result, data here will compensate you once your valued feelings have been traded.
  </p>
  <p>
  If you're a Lets Gtok user–and let's be honest, you probably are–you might think the pricing is reasonable. After all, "free" has a good ring to it.
  </p>
  <p>
  You get a free platform (indeed a digital platform), where you may engage with friends and acquaintances, share and trade feelings. Zip. Nada. Isn't it a great deal? Is there any way it could get any better?
  </p>
  <p>
  You might be Mark Zuckerberg, who stands to make $24 billion from Facebook's impending first public offering. But wait, how did Zuckerberg become so wealthy while Facebook is free? Of course, you already know the answer: advertising. Facebook owns your data and can profit handsomely from it. You get to poke him while he becomes a multi-billionaire. Isn't it fair trade?
  </p>
  <p>
  Of course, I'm simplifying. However, a nagging suspicion that something is wrong with this equation has prompted some to wonder if the people who should be profiting from their data are the people whose data it is in the first place.
  </p>
  <p>
  "There may come a social networking site where you continue to use the site for free, but if a firm wants to exploit your data, they reimburse you," I once heard. To put it another way, imagine a Facebook that pays you.
  </p>
  <p>
  But don't simply rely on those social media sites; Lets Gtok is far more than you'll ever know, see, or experience. Your data (#personal_data) is entirely protected.
  </p>
  <div class="text-center mt-2">
    <b>Happy sharing. Happy earning.</b>
  </div>
</div>`
}

export default blog;
