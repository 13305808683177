const blog = {
  name: 'what-can-we-do',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/what-can-we-do_LetsGtok.jpg',
  title: 'What can we do when we are not feeling ourselves?',
  meta_description: 'We\'ve all been in a spot in our life where we\'ve felt worn out...',
  meta_keywords: 'feelings,emotions,share,sharing,understand,funk,accept,waves',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  date: '10 Oct 2021',
  body: `<div>
  Have you ever remarked, "I just don't feel like myself any longer"?<br/>
We've all been in a spot in our life where we've felt worn out, bored, stagnant, or just plain blah at one point or another.
<br/><br/>
You may not understand the reason for this change, but you will remember a time when you were more carefree and adventurous.
<br/><br/>
You know deep down that you have a higher purpose, more joy, or greater talent, but you don't know how to access it.
<br/><br/>
What are you going to do with that? Most people, unfortunately, tend to bury it.
<br/><br/>
Feelings are like waves; they ebb and flow in the same way that ocean waves do. When we battle a wave, it often knocks or pulls us down; however, if we surrender and accept it, it will move us a little, but then deposit us back or close to where we started — undamaged.
<br/><br/>
Practicing self-compassion can help you get through a difficult situation. Consider talking to yourself as if you were talking to a friend. We may get harsh or impatient with ourselves, but if a friend came to us going through something similar, we'd treat them with care and empathy.
<br/><br/>
We'd reassure our friends that everything will be fine and remind them that they're nice people and that this situation wouldn't endure forever.
<br/><br/>
So go ahead and demonstrate the same grace and gentleness to yourself.
<br/><br/>
Here are five easy actions to help you get back to your sense of self:<br/><br/>
<ol>
  <li>Accept the funk:<br/>
  If you're having a bad day, don't be too hard on yourself. Slumps happen now and then, and they can happen to anyone. Most people are in a funk for a few days before the clouds clear and they feel like themselves again.
  </li><br/>
  <li>Take some time to relax and unwind:<br/>
  We should not consider busyness to be a badge of honor. In return for a more meaningful and influential existence, it saps our vitality and joy. However, when we are immersed in a busy culture, we abandon our natural purpose in favor of nervous activity.<br/>
  We can't hear our souls' whispers revealing the mysteries of what we were created for unless we have white space in our lives.
  </li><br/>
  <li>Breaking free from a rut:<br/>
  When you're feeling off or not yourself, focus on these 3 pillars: <br/><br/>
  <ul style="list-style-type:disc;">
    <li><strong>Connection. </strong> <br />
    We are sociable animals as humans. To feel our best, we require some level of engagement with others. Connecting with people, whether it's a phone call to a friend, dinner with your parents, or simply waving to your neighbor, makes us feel more fulfilled.
    </li><br/>
    <li><strong>Eating well is important. </strong> <br/>
    Because our emotions and belly are linked, it's critical to eat nutritious meals and limit sugar intake, especially when we're going through a difficult moment. It's also a good idea to avoid relying on quick fixes, such as alcohol, to make you feel better. Since alcohol is depressant, it may have an effect on your mood for several days after you consume it.
    </li><br/>
    <li><strong>Concentrate on getting enough rest. </strong><br/>
    Sleep is a necessity, not a luxury. Sleep deprivation has several negative effects on our health. Low energy, forgetfulness, and weight gain are just a few of the symptoms. Sleep deprivation is significant, and you should make it a priority, especially if you're not feeling well.
    </li>
  </ul>
  </li><br/>
  <li>Get your mind out of the trash. <br/>
  Every now and again, we all need a vacation from our thoughts. So, if you're being particularly hard on yourself because you don't feel like yourself, getting out of your head and back into your life might help you shake this strange phase. Do something that causes you to spend less time thinking and more time being, laughing, and connecting with other people and activities.
  </li><br/>
  <li>Have faith in yourself.<br/>
  No matter what, trust your inner resources, and you'll benefit from the experience. I believe that the answers are usually found within and that you are capable of determining what you need to accomplish. Be patient and give yourself some time.
  </li>
</ol>
  <div class="text-center mt-2">
  <b> START NOW AND EXPERIENCE THE FEELING</b><br/><br/>
  <a href="https://app.letsgtok.com/app/create_post" target="_blank"><button class="btn btn-violet-v2 btn-rounded">Start sharing</button></a>
  </div>
</div>`
}

export default blog;
