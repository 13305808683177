const blog = {
  name: 'christmas-now-and-then',
  category: 'Sharing',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/christmas-now-and-then_LetsGtok.jpg',
  title: 'Christmas NOW and THEN',
  date: '26 Dec 2021',
  meta_description: 'Even though I was born and raised in India, my tastes have always been international...',
  meta_keywords: 'christmas, personal, feelings, share, thoughts, experiences, festival',
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/in/s0nali/' target='_blank' style='color: #6930DB !important;'>Sonali Sood</a></div>`,
  body: `<div>
  <p>
  So, to begin, let me tell you how curious and happy I used to be around December, also known as the worldwide holiday season ❄️
  </p>
  <p>
  Even though I was born and raised in India, my tastes have always been international, whether in apparel, accessories, travel, or eating.
  </p>
  <p>
  However, knowing how stubborn I was, my mother went out of her way to make my Christmas week extra special.
  </p>
  <p>
  I mean, when I was in my teens, all I knew about Christmas was that it was all about presents, snow, and red-colored clothes 🎁❄️🎅
  </p>
  <p>
  So all she used to do was decorate the front entrance with lights, hang red and white socks, and set up a small Christmas tree  with gifts, bells, candies, and a star on top, claiming that Santa would come and leave you a present while you are asleep.
  </p>
  <p>
  And I used to think to myself,
  </p>
  <p>
  "Wow, I'll be receiving gifts in the morning."
  </p>
  <p>
  So, before I woke up, my mother made sure to put a lot of candies (my favorite ones, though!) in red socks hanging at the front entrance and to buy me a gift that I might have demanded of her on any random day.
  </p>
  <p>
  The morning of December 25th used to be the happiest morning of the month since I could see my gift right in front of me as soon as I opened my eyes.
  </p>
  <p>
  And what my mother would tell me that,
  </p>
  <p>
  "Santa had left me a bunch of candies in the main hall," and I should go check it out. I'd dash away like a madman, collecting all the sweets on my own without sharing them with my brother, and he'd say, "you are super selfish, huh!"
  </p>
  <p>
  Despite this, he never fought me for things because he believes in giving (he is another Mahatma Gandhi, I must say), and I used to steal all of his presents and candies.
  </p>
  <p>
  A fact of life - I'm the elder one! 😝
  </p>
  <p>
  I used to think my brother and mother were my secret Santas, trying their hardest to make me happy every Christmas season and ensuring everything went the way I always demanded.
  </p>
  <p>
  I mean, I had a fantasy of spending Christmas like in "Home Alone," where I could pamper myself in any manner I wanted with "DADDY'S CASH," 😂 but these are just teenage fantasies.
  </p>
  <p>
  Oh, and I almost forgot to include my school's Christmas festivities…
  </p>
  <p>
  But I’ll tell you about it in my next blog… Wait for it 😉
  </p>
</div>`
}

export default blog;
