import React, { useState, useEffect } from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";

const MobileFooterComponent = () => {
  const [offset, setOffset] = useState(0);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", getOffsetWindow)
  }, [])

  function getOffsetWindow() {
    setOffset(window.pageYOffset)
  }

  return (
    location.pathname.includes('/blogs')
      ?
      <div className="footer-section-mobile-wrapper d-sm-none mobile">
        <div className="d-sm-none blog-footer-mobile">
          <div className="d-flex justify-content-center">
            <div className="blog-mobile-footer-background-with-content">
              <img src={require("assets/images/footer_bg_mobile.png").default} alt="background 1" className="blog-footer-background-mobile" />
              <div className="row justify-content-center align-items-center">
                <div className="footer-links-mobile footer-follow-up overlay-footer-links-follow">
                  <div className="footer-follow-up col">
                    <a href="https://www.facebook.com/letsgtok" target="_blank" rel="noopener noreferrer" className="small px-3">
                      <img className="" src={require("assets/svgs/logo_facebook_white.svg").default} alt="1" />
                    </a>
                    <a href="https://www.instagram.com/letsgtok" target="_blank" rel="noopener noreferrer" className="small px-3">
                      <img className="" src={require("assets/svgs/logo_instagram_white.svg").default} alt="1" />
                    </a>
                    <a href="https://www.linkedin.com/company/letsgtok/" target="_blank" rel="noopener noreferrer" className="small px-3">
                      <img className="" src={require("assets/svgs/logo_linkedin_white.svg").default} alt="1" />
                    </a>
                  </div>
                </div>
                <div className="footer-links-mobile overlay-footer-links col my-auto">
                  <Link to="/contact" className="blog-footer-links">
                    Ambassadors
                  </Link>
                  <br />
                  <Link to="/faq" className="blog-footer-links">
                    Contact
                  </Link>
                  <br />
                  <Link to="/faq" className="blog-footer-links">
                    FAQ
                  </Link>
                  <br />
                  <Link to="/tnc" className="blog-footer-links">
                    Terms and conditions
                  </Link>
                  <br />
                  <Link to="/privacy_policy" className="blog-footer-links">
                    Privacy policy
                  </Link>
                  <br />

                </div>

                <div className="company-info">
                  <Link to="/" className="mt-3" >
                    <span className="navbar-brand-logo">
                      <img src={require("assets/svgs/logo_white.svg").default} alt="logo-white" className="mt-5"></img>
                    </span>
                  </Link>
                  <div className="company-name">
                    <p className="small mt-1">
                      <small>
                        Perla Infotech Pvt Ltd®
                      </small>
                    </p>
                    <p className="small mt-4">
                    <small className="mt-5">Copyrights 2021 &middot; All rights reserved</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      :

      <div className={`mobile-footer-wrapper d-sm-none ${offset > 600 ? "container fixed-bottom sticky-after" : "container d-sm-none"}`}>
        <div className="row">
          <div className="col-6">
            <a href="https://app.letsgtok.com/login" target="_blank" rel="noopener noreferrer" className="btn btn-violet-outline">
              Log in
            </a>
          </div>
          <div className="col-6">
            <a href="https://app.letsgtok.com/signup" target="_blank" rel="noopener noreferrer" className="btn btn-violet-v2">
              Sign up
            </a>
          </div>
        </div>
      </div>
  );
};

export default MobileFooterComponent;
