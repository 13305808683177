import React from "react";
import { Link } from "react-router-dom";

const CommonComponent = () => {
  return (
    <div>
        <div className="container footer-wrapper">
          <div className="my-4">
            <a href="https://www.facebook.com/letsgtok" target="_blank" rel="noopener noreferrer" className="small px-3 px-sm-5">
              <img className="" src={require("assets/svgs/logo_facebook.svg").default} alt="1" />
            </a>
            <a href="https://www.instagram.com/letsgtok" target="_blank" rel="noopener noreferrer" className="small px-3 px-sm-5">
              <img className="" src={require("assets/svgs/logo_instagram.svg").default} alt="1" />
            </a>
            <a href="https://www.linkedin.com/company/letsgtok/" target="_blank" rel="noopener noreferrer" className="small px-3 px-sm-5">
              <img className="" src={require("assets/svgs/logo_linkedin.svg").default} alt="1" />
            </a>
          </div>
          <div className="my-4 options justify-content-center  d-none d-sm-block">
            <Link to="/careers" className="col-2 small border-right-2 mx-2">
              Careers
            </Link>
            <span><strong>&#124;</strong></span>
            <Link to="/contact" className="col-2 small mx-2">
              Help center
            </Link>
            <span><strong>&#124;</strong></span>
            <Link to="/faq" className="col-2 small mx-2">
              FAQ
            </Link>
            <span><strong>&#124;</strong></span>
            <Link to="/tnc" className="col-3 small mx-2">
              Terms and conditions
            </Link>
            <span><strong>&#124;</strong></span>
            <Link to="/privacy_policy" className="col-2 small mx-2">
              Privacy policy
            </Link>
          </div>

          <div className="mb-3 mt-1 col-12 mobile-options justify-content-center  d-sm-none">
            <Link to="/careers" className="col-4 small mx-2">
              Careers
            </Link>
            <br />
            <Link to="/faq" className="col-4 small mx-4">
              FAQ
            </Link>
            <br />
            <Link to="/tnc" className="col-4 small mx-2">
              Terms and conditions
            </Link>
            <br />
            <Link to="/privacy_policy" className="col-4 small mx-4">
              Privacy policy
            </Link>
          </div>
          <div className="small">
            <small>Perla InfoTech Pvt Ltd &#174;</small>
          </div>
        </div>
      </div>
  )
}

export default CommonComponent;
