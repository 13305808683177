import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "assets/svgs/logo.svg";

const HeaderComponent = () => {
  const [offset, setOffset] = useState(0);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", getOffsetWindow);
  }, []);

  function getOffsetWindow() {
    setOffset(window.pageYOffset);
  }

  return (
    <nav
      className={`navbar navbar-expand-lg ${offset > 200 ? "fixed-top sticky-after" : "fixed-top"
        }`}
    >
      {
        location.pathname.includes('/blogs')
          ?
          <div  className="container py-2">
            <Link to="/" className="navbar-brand d-none d-sm-flex" >
              <span className="navbar-brand-image">
                <img src={logo} alt="letsgtok, gtok, feelings" id="navbar-brand-logo"></img>
              </span>
            </Link>

            <Link to="/" className="navbar-brand d-sm-none  mx-5" >
              <span className="navbar-brand-image  align-items-center ">
                <img src={logo} alt="letsgtok, gtok, feelings" id="navbar-brand-logo-blog" className="mx-4"></img>
              </span>
            </Link>
            <ul className="navbar-nav ml-auto text-center d-none d-sm-flex blog-nav-options">
              <li className="nav-item px-sm-3 mt-3 mt-sm-0 join-btn">
                <a href="https://app.letsgtok.com/login" target="_blank" rel="noopener noreferrer" className="btn btn-violet-outline">
                  Login
                </a>
              </li>
              <li className="nav-item px-sm-3 mt-5 pt-3 pt-sm-0 mt-sm-1 join-btn">
                <a href="https://app.letsgtok.com/signup" target="_blank" rel="noopener noreferrer" className="btn btn-violet-news btn-violet-v2">
                  Sign up
                </a>
              </li>
            </ul>
          </div>


          :


          <div className="container py-2 px-4">
            <Link to="/" className="navbar-brand" >
              <span className="navbar-brand-image">
                <img src={logo} alt="letsgtok, gtok, feelings" id="navbar-brand-logo"></img>
              </span>
            </Link>
            <button
              className="navbar-toggler custom-toggler btn"
              data-toggle="collapse"
              data-target="#CollapseNavbar"
              aria-controls="CollapseNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="CollapseNavbar">
              <ul className={`navbar-nav ml-auto text-center ${offset > 200 ? "move-right-with-join" : "move-right-without-join"}`}>
                <li className={`nav-item px-sm-2 ${offset > 200 ? "col-4" : "col-0"}`} title='Switch'>
                  {
                    location.pathname.includes('/trade')
                    ? <Link to='/' className="nav-link">
                      Switch To Share
                    </Link>
                    : <Link to='/trade' className="nav-link">
                      Switch To Trade
                    </Link>
                  }
                </li>
                <li className='nav-item px-sm-2' title='Catalysts'>
                  <Link to='/catalysts' className="nav-link">
                    Catalysts
                  </Link>
                </li>
                <li className="nav-item px-sm-2" title="Blogs">
                  <Link to="/blogs" className="nav-link">
                    Blogs
                  </Link>
                </li>
                <li className={`nav-item px-sm-3 ${offset > 200 ? "join-btn" : "join-btn d-sm-none"}`} title='Join in'>
                  <a href="https://app.letsgtok.com" target="_blank" rel="noopener noreferrer" className="btn btn-violet-news btn-violet-v3-small">
                    Join in
                  </a>
                </li>
              </ul>

            </div>
          </div>
}
    </nav>
  );
};

export default HeaderComponent;
