import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  HomeComponent,
  BlogsComponent,
  CareersComponent,
  TncComponent,
  PrivacyPolicyComponent,
  CookiePolicyComponent,
  FaqComponent,
  ContactFormComponent,
  CatalystComponent,
  CatalystDutiesComponent,
  CatalystExpectationsComponent,
  CatalystBenefitsComponent,
  BlogSingleComponent,
  UnsubscribeComponent,
  DefaultLayout,
  TradeComponent
} from "components";

const DefaultRoute = ({path, exact, component}) => (
  <DefaultLayout>
    <Route key={path} path={path} exact={exact} component={component} />
  </DefaultLayout>
)

export const Routes = () => (
  <Switch>
    <DefaultRoute exact path="/" component={HomeComponent} />
    <DefaultRoute path="/home" component={HomeComponent} />
    <DefaultRoute path="/trade" component={TradeComponent} />
    <DefaultRoute exact path="/careers" component={CareersComponent} />
    <DefaultRoute exact path="/contact" component={ContactFormComponent} />
    <DefaultRoute exact path="/blogs" component={BlogsComponent} />
    <DefaultRoute exact path="/blogs/:name" component={BlogSingleComponent} />
    <DefaultRoute exact path="/tnc" component={TncComponent} />
    <DefaultRoute exact path="/privacy_policy" component={PrivacyPolicyComponent} />
    <DefaultRoute exact path="/cookie_policy" component={CookiePolicyComponent} />
    <DefaultRoute exact path="/faq" component={FaqComponent} />
    <DefaultRoute exact path="/unsubscribe" component={UnsubscribeComponent} />
    <Route exact path="/catalysts" component={CatalystComponent} />
    <Route exact path="/catalysts_duties" component={CatalystDutiesComponent} />
    <Route exact path="/catalysts_expectations" component={CatalystExpectationsComponent} />
    <Route exact path="/catalysts_benefits" component={CatalystBenefitsComponent} />
  </Switch>
);

export default Routes;
