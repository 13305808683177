const blog = {
  name: 'revamp-version-3-0',
  category: 'Events',
  banner_url: 'https://storage.googleapis.com/gtok-web.appspot.com/blogs/revamp-version-3-0_LetsGtok.jpg',
  title: 'Lets Gtok Revamp: Version 3.0',
  meta_description: 'Sharing or expressing our sentiments is important, isn’t it?...',
  meta_keywords: 'feelings,emotions,share,friends,benefits',
  date: '30 Oct 2021',
  body: `<div>
  <p>
  Finally, LetsGtok is going to be revamped with its new version 3.0 which is going to be live on 1st November 2021! We are thrilled to present our months of hard work in bringing the new features and updates to you. Thank you for all the support, this journey is just getting started.
  </p>
  <p>
  We recognize the importance of offering the most significant possible update experience to help people and organizations stay protected and productive, mainly as people rely on our platform more than ever to share and trade feelings.
  </p>
  <p>
  It's a responsibility we don't take lightly, which is why, for the first time, a feature update release will be delivered efficiently using servicing technology.
  </p>
  <p>
  As we all know, security is an essential consideration. Thus the version is updated to deliver the highest potential performance gains.
  </p>
  <p>
  Here's everything you need to know about Lets Gtok v3.0
  </p>
  <ul>
    <li>
      <b>Share assets:</b> <br/>
      <p>
      This new version, v3.0, allows you to express yourself in any way you want, whether it's text or audio. Feeling sluggish? or have you been feeling a little lazy lately?
      </p>
      <p>
      We are confident that this new function will significantly assist you in interacting with our site. You can now share your asset, i.e., your feelings, in any way you like.
      </p>
    </li>
    <li>
      <b>Trade assets:</b> <br/>
      <p>
      Similar to asset sharing, trading has become more accessible. You can now virtually trade your emotions.
      </p>
      <p>
      This function is comprehensive and designed specifically for those who like to engage in writing and do other similar activities. You have the option of trading your assets in text or audio format.
      </p>
      <p>
      This new feature will allow users to communicate their sentiments across many social channels effortlessly. The cherry on top is that the upgraded version will house all of your shared or exchanged feelings i.e., once created, your asset will never be erased but can only be hidden.
      </p>
    </li>
    <li>
      <b>Same-Pinch:</b> <br/>
      <p>
      We used to get a kick out of the thought of being similar to each other when we were kids. Pinching one another and screaming, "Same pinch!" was a way of expressing similarity.
      </p>
      <p>
      Do your t-shirts have the same color? In the same boat! Did you have a chance to earn the same grades? Are you in the same situation? Do you have a birthday in common?
      </p>
      <p>
      This childhood experience of "same pinch" will be relieved in our new enhanced version of LetsGtok, where users will be able to pinch others’ shared feelings if they find it similar or related to their own.
      </p>
    </li>
    <li>
      <b>Fresh and appealing user interface:</b> <br/>
      <p>
      Our new interface is highly user-friendly, and we are confident that you will enjoy visiting our website.
      </p>
      <p>
      This website gets everything right with its limited color, generous line spacing, and well-chosen typeface.
      </p>
      <p>
      Blogs are organized to make content digestible, with features such as predicted read time and the option to give a heart to our writers.
      </p>
      <p>
      Trading is also safe and may be performed in a matter of seconds.
      </p>
    </li>
    <li>
      <b>Making investing easier by using a secure wallet:</b> <br/>
      <p>
      LetsGtok wallet is a multi-purpose digital/mobile wallet that is safe, secure, and approved. It functions similarly to digital cash in that it may be used to trade assets (feelings).
      </p>
      <p>
      Money can be added through credit/debit cards into your wallet.
      </p>
      <p>
      The money in your Lets Gtok wallet is like ready-to-use cash that you can use to trade on our platform at any time.
      </p>
      <p>
      Users can withdraw their earnings with ease. We have modified our new version to allow you to recharge or withdraw at your leisure. We understand your emotions, but we also cherish your security, so keeping that in mind we have made Lets Gtok wallet safe and secure with end-to-end password encryption. So relax and enjoy this new era of asset trading with Lets Gtok.
      </p>
      <p>
      Furthermore, the wallet's extra feature of freezing transactions allows users to stop their future transactions.
      </p>
    </li>
    <li>
      <b>Identity secrecy:</b> <br/>
      <p>
      Yes, you read that correctly: you can keep your identity hidden while still sharing your emotions on LetsGtok. Isn't it great? To be able to express any feeling without the worry about being criticized freely.
      </p>
    </li>
    <li>
      <b>ZENA bot:</b> <br/>
      <p>
      A formidable guide for you.
      </p>
      <p>
      Zena bot will communicate with you and assist you with issues that may cause you to become trapped. Don't worry; Zena will not let you down; all she wants to do is keep you informed.
      </p>
      <p>
      Just to let you know, she is talkative and kind. <a href='https://letsgtok.com/blogs/zena' style="color: #6930db !important;">Know more about Zena.</a>
      </p>
    </li>
    <li>
      <b>Get exposure (follow and get followed):</b> <br/>
      <p>
      Make friends with people from all around the world and get to know them. <br/>
      With this new version, you may follow individuals and observe their activity, and people can follow you back in return.
      </p>
      <p>
      By learning their emotions, you can connect and meet new acquaintances on Lets Gtok, expanding your network. Ensure you recognize anything in a primary, natural way, whether genuine connection or a welcoming attitude.
      </p>
    </li>
    <li>
      <b>Invite your friends:</b> <br/>
      <p>
      You can easily invite your friends and share or trade feelings with them on Lets Gtok. After you've finished inviting and sharing LetsGtok with your friends, you'll be able to see the total number of registered users with your invite.
      </p>
    </li>
    <li>
      <b>Activities & Feelings:</b> <br/>
      <p>
      The previous version featured separate sections for sharing or trading of feelings and activities; however, the most recent upgrade will consolidate both into one, making sharing and trading of feelings the top priority because emotions are all that matters in the end.
      </p>
    </li>
    <li>
      <b>Profile Privacy:</b> <br/>
      <p>
      You have the option of creating a Public or Private Profile. You can share your feelings in one of two ways: privately with a select group of friends or publicly with all LetsGtok members. It's entirely up to you ;)
      </p>
    </li>
  </ul>
  <p>
  We hope you enjoy our new updates and features, which will enhance your overall experience with us. Let us know what else you'd like to see from us. Just ping us on our <a href='https://discord.gg/7H7pBb8gzE' target="_blank" style="color: #6930db !important;">Discord</a> channel.
  </p>
  <p style="text-align: center">
  Share Confidently. Trade Securely.
  </p>
</div>`
}

export default blog
